import React from 'react'

import { Group, ScrollArea, Stack, Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { SnapshotSummary } from '@util/ScriptoApiClient/types'

import { SnapshotViewType } from './helpers'
import { SelectionMode, SnapshotCard } from './SnapshotCard'
import { SnapshotFilter } from './SnapshotFilter'
import { StickySnapshotCard } from './StickySnapshotCard'
import { useSelectedSnapshots, useSnapshotHistory } from './useSnapshotLandData'
import { useSnapshotQueryParams } from './useSnapshotQueryParams'

import styles from './SnapshotList.module.scss'

const StickyCards = ({ mode }: { mode: SelectionMode }) => {
  return (
    <Stack gap={10} className={styles.snapshotList_selectedSection}>
      <StickySnapshotCard position="top" />
      {mode === 'comparison' && <StickySnapshotCard position="bottom" />}
    </Stack>
  )
}

const HEADERS: Record<SnapshotViewType, string> = {
  static: 'Snapshots',
  asterisk: 'Snapshot comparison',
  diff: 'Snapshot comparison',
}

export const SnapshotList = observer(function SnapshotList({
  scriptId,
}: {
  scriptId: string
}) {
  const { view, selectSnapshotId, selectedIds, swapSnapshots, snap1, snap2 } =
    useSnapshotQueryParams()
  const historyQuery = useSnapshotHistory({ scriptId, from: 0 })
  const { topQuery, bottomQuery } = useSelectedSnapshots(scriptId)

  // if we are comparing with an older one on the right side,
  // swap them
  React.useEffect(() => {
    if (topQuery.data && bottomQuery.data) {
      if (topQuery.data.version < bottomQuery.data.version) {
        swapSnapshots()
      }
    }
  }, [bottomQuery.data, topQuery.data, swapSnapshots, snap1, snap2])

  const data: SnapshotSummary[] = historyQuery.data?.results ?? []

  return (
    <Stack gap={0} className={styles.snapshotList}>
      <Group
        wrap="nowrap"
        justify="space-between"
        align="center"
        className={styles.snapshotList_header}
        gap={5}
        pb={10}
        px={10}
        pt={10}
      >
        <Text lineClamp={1} fw="bold">
          {HEADERS[view]}
        </Text>
        <SnapshotFilter />
      </Group>
      <StickyCards mode={view === 'static' ? 'solo' : 'comparison'} />
      <ScrollArea className={styles.snapshotList_scroller}>
        <Stack gap={10} className={styles.snapshotList_contents}>
          {data.map((snapshot) => (
            <SnapshotCard
              key={snapshot.id}
              data={snapshot}
              selected={selectedIds.includes(snapshot.id)}
              onClick={() => selectSnapshotId(snapshot.id)}
            />
          ))}
        </Stack>
      </ScrollArea>
    </Stack>
  )
})
