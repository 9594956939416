import React from 'react'

import { useDebouncedElementSize } from '@hooks'
import { useMst } from '@state'
import { BlockFormats } from '@util/formats'
import { PaginationType } from '@util/prosemirrorHelpers/types'

import { buildFormatStyleProp } from './buildFormatStyles'
import { PagelessWrapper } from './PagelessWrapper'
import { PrintPreviewWrapper } from './PrintPreviewWrapper'

import styles from './ScriptWrapper.module.scss'

export const ScriptWrapper = ({
  children,
  pageless,
  noWrap,
  zoomLevel,
  commentsPanel,
  pageCount,
  blockFormats,
  paginationType,
}: {
  pageCount: number
  noWrap?: boolean
  pageless?: boolean
  children: React.ReactNode
  zoomLevel: number
  commentsPanel?: React.ReactNode
  blockFormats?: BlockFormats
  paginationType?: PaginationType
}) => {
  const { ref, height, width } = useDebouncedElementSize()
  const { view } = useMst()

  // debug behavior: use a standard stylesheet for all scripts
  // and regenerate a set of css vars whenever the format definition
  // changes
  const formatStyleVars: React.CSSProperties | undefined = React.useMemo(
    () => (blockFormats ? buildFormatStyleProp(blockFormats) : undefined),
    [blockFormats],
  )

  React.useEffect(() => {
    view.dimensions.scriptScroller.update({
      height,
      width,
    })
  }, [height, view.dimensions.scriptScroller, width])

  const inlineStyles: React.CSSProperties = {
    ...formatStyleVars,
    '--total-page-count': `' of ${pageCount}'`,
    '--zoom-level': zoomLevel,
    '--prose-editor-height': `${height}px`,
    '--prose-editor-width': `${width}px`,
  }
  if (pageless) {
    inlineStyles['--pageless-block-width'] = 'auto'
  }

  return (
    <div className={styles.scriptAndComments}>
      <div ref={ref} className={styles.scriptWrapper} style={inlineStyles}>
        {paginationType === 'none' || pageless ? (
          <PagelessWrapper
            isStructural={paginationType === 'structural'}
            noWrap={noWrap}
          >
            {children}
          </PagelessWrapper>
        ) : (
          <PrintPreviewWrapper
            isStructural={paginationType === 'structural'}
            zoomLevel={zoomLevel}
          >
            {children}
          </PrintPreviewWrapper>
        )}
      </div>
      {commentsPanel}
    </div>
  )
}
