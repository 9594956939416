import React from 'react'

import { useRoute } from 'wouter'

import { useMst, useNavigation } from '@hooks'
import { notEmptyFilter, ROUTE_PATTERNS } from '@util'

import { isViewType, SnapshotViewType } from './helpers'

const VIEW = 'view'
const COMPARE_PARAM_KEY = 'compare'
const SNAPSHOT1 = 'snap1'
const SNAPSHOT2 = 'snap2'

const parseParams = (
  search: string,
): {
  snap1?: string
  snap2?: string
  view: SnapshotViewType
} => {
  const params = new URLSearchParams(search)
  const snap1 = params.get(SNAPSHOT1) ?? undefined
  const snap2 = params.get(SNAPSHOT2) ?? undefined
  const rawView = params.get(COMPARE_PARAM_KEY)
  const view = isViewType(rawView) ? rawView : 'static'

  return {
    snap1,
    snap2,
    view,
  }
}

const buildParams = ({
  search,
  key,
  value,
}: {
  search: string
  key: string
  value?: string
}) => {
  const params = new URLSearchParams(search)
  if (value) {
    params.set(key, value)
  } else {
    params.delete(key)
  }
  return params.toString()
}

export const useSnapshotQueryParams = () => {
  const { location } = useMst()
  const { search } = location
  const { changeSearch } = useNavigation()
  const [, routeParams] = useRoute(ROUTE_PATTERNS.scriptHistory)

  const params = parseParams(location.search)

  const setView = (view: SnapshotViewType) => {
    const value = view === 'static' ? undefined : view
    changeSearch(buildParams({ search, key: VIEW, value }))
  }

  const setSnapshotId = (id: string, isFirst: boolean) => {
    const key = isFirst ? SNAPSHOT1 : SNAPSHOT2
    changeSearch(buildParams({ search, key, value: id }))
  }

  const clearKey = (key: string) => {
    changeSearch(buildParams({ search, key }))
  }

  const selectedIds: string[] = [params.snap1, params.snap2].filter(
    notEmptyFilter,
  )

  // different logic depending on whether one or two snapshots can be selected
  // in the current view
  const selectSnapshotId = (id: string) => {
    if (selectedIds.includes(id)) {
      return
    }

    if (params.view === 'static') {
      setSnapshotId(id, true)
    } else {
      setSnapshotId(id, !params.snap1)
    }
  }

  const swapSnapshots = () => {
    const { snap1, snap2 } = params
    if (snap1 && snap2) {
      const search1 = buildParams({ search, key: SNAPSHOT1, value: snap2 })
      const search2 = buildParams({
        search: search1,
        key: SNAPSHOT2,
        value: snap1,
      })
      changeSearch(search2)
    }
  }

  // if we switch views to static, clear out snap2.
  React.useEffect(() => {
    if (params.view === 'static' && params.snap2) {
      clearKey(SNAPSHOT2)
    }
  })

  return {
    ...params,
    scriptId: routeParams?.scriptId,
    setView,
    selectedIds,
    selectSnapshotId,
    swapSnapshots,
    dismissTop: () => {
      clearKey(SNAPSHOT1)
    },
    dismissBottom: () => {
      clearKey(SNAPSHOT2)
    },
  }
}
