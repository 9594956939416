import { format } from 'date-fns'

import { SnapshotSummary } from '@util/ScriptoApiClient/types'

const VIEW_TYPES = ['static', 'asterisk', 'diff'] as const
export type SnapshotViewType = (typeof VIEW_TYPES)[number]

export const COMPARE_PARAM_KEY = 'compare'
export const SNAPSHOT1 = 'snap1'
export const SNAPSHOT2 = 'snap2'
export const CURRENT = 'current'

export const getViewType = (queryParams: StringMap): SnapshotViewType => {
  const value = queryParams[COMPARE_PARAM_KEY]
  if (value === 'diff') {
    return 'diff'
  }
  if (value === 'asterisk') {
    return 'asterisk'
  }
  return 'static'
}

export function isViewType(value: unknown): value is SnapshotViewType {
  return (
    typeof value === 'string' && VIEW_TYPES.includes(value as SnapshotViewType)
  )
}

export const getSnapshotQueryParams = (
  search: string,
): {
  snap1?: string
  snap2?: string
  view: SnapshotViewType
} => {
  const params = new URLSearchParams(search)
  const snap1 = params.get(SNAPSHOT1) ?? undefined
  const snap2 = params.get(SNAPSHOT2) ?? undefined
  const rawView = params.get(COMPARE_PARAM_KEY)
  const view = isViewType(rawView) ? rawView : 'static'

  return {
    snap1,
    snap2,
    view,
  }
}

export const swapViewType = ({
  search,
  newType,
}: {
  search: string
  newType: SnapshotViewType
}): string => {
  const params = new URLSearchParams(search)
  if (newType === 'static') {
    params.delete(COMPARE_PARAM_KEY)
  } else {
    params.set(COMPARE_PARAM_KEY, newType)
  }
  return params.toString()
}

export const setSnap1 = ({ search, id }: { search: string; id: string }) => {
  const params = new URLSearchParams(search)
  params.set(SNAPSHOT1, id)
  return params.toString()
}

export const setSnap2 = ({ search, id }: { search: string; id: string }) => {
  const params = new URLSearchParams(search)
  params.set(SNAPSHOT2, id)
  return params.toString()
}

export const snapshotDescription = (snapshot: SnapshotSummary) => {
  // traps for the edge case here
  // https://github.com/showrunner/models/blob/d5fbad213952f0fbb8c35d406586f8cd2308f7ba/lib/dashboard-template-script.js#L76
  switch (snapshot.autoSave) {
    case 'session':
      return 'Writing session ended '
    case 'backup':
      return 'Backup autosaved '
    case 'export':
    case 'prompter':
    case 'rundown':
      return 'Exported '
    case 'copied':
      return 'Copied '
    case 'pushToPrompter':
      return 'Pushed to prompter '
    default:
      return 'Saved '
  }
}
export const snapshotAuthor = (snapshot: SnapshotSummary) => {
  const known = `by ${snapshot.creatorName} `
  switch (snapshot.autoSave) {
    // backups and sessions arent attributed to a user
    case 'session':
    case 'backup':
      return ''
    default:
      return known
  }
}

export const title = (snapshot: SnapshotSummary) => {
  if (snapshot.name) {
    return snapshot.name
  }
  return 'Autosaved backup'
}

export const subtitle = (snapshot: SnapshotSummary): string => {
  const snapshotCreationDate = new Date(snapshot.createdAt)
  const formattedTime = format(snapshotCreationDate, 'Pp')
  if (snapshot.name && snapshot.creatorName) {
    return `Saved by ${snapshot.creatorName} at ${formattedTime}`
  }
  return formattedTime
}
