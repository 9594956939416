import { useQuery } from '@tanstack/react-query'

import { useMst } from '@state'

import { useSnapshotQueryParams } from './useSnapshotQueryParams'

/*
  notes about staleTime/cacheTime

  staleTime means: "How long is this query considered valid"
  cacheTime means: "When no longer mounted, when should it be garbage collected"

  While in snapshot land for a script, we need to balance between reusing API
  responses and using too much memory.

  For the "current version" of the script, we should consider the value to
  be invalid pretty quickly, but it's still in discussion how this should work

  Snapshots on the other hand are immutable, so staleTime is Infinity, but we don't
  want to over-cache them.

*/
const minutesToMillis = (minutes: number) => minutes * 60 * 1000
export const useSnapshotData = ({
  snapshotId,
  scriptId,
}: {
  snapshotId: string
  scriptId: string
}) => {
  const { apiClient } = useMst()
  return useQuery({
    queryFn: () => apiClient.getSnapshot({ scriptId, snapshotId }),
    queryKey: ['snapshotland-snapshot', { scriptId, snapshotId }],
    // if we have a snapshot in the cache, it is not stale
    staleTime: Infinity,
    // keep for 5 minutes if a snapshot is unmounted
    cacheTime: minutesToMillis(5),
  })
}

export const useMaybeSnapshot = ({
  snapshotId,
  scriptId,
}: {
  snapshotId?: string
  scriptId: string
}) => {
  const { apiClient } = useMst()
  return useQuery({
    queryFn: async () => {
      if (snapshotId) {
        return apiClient.getSnapshot({ scriptId, snapshotId })
      }
      return null
    },
    queryKey: ['snapshotland-snapshot', { scriptId, snapshotId }],
    // if we have a snapshot in the cache, it is not stale
    staleTime: Infinity,
    // keep for 5 minutes if a snapshot is unmounted
    cacheTime: minutesToMillis(5),
  })
}

export const useSelectedSnapshots = (scriptId: string) => {
  const { snap1, snap2 } = useSnapshotQueryParams()
  const topQuery = useMaybeSnapshot({ scriptId, snapshotId: snap1 })
  const bottomQuery = useMaybeSnapshot({ scriptId, snapshotId: snap2 })

  return {
    topQuery,
    bottomQuery,
  }
}

export const useScriptData = (scriptId: string) => {
  const { apiClient } = useMst()
  return useQuery({
    queryFn: async () => {
      const script = await apiClient.getScript(scriptId)
      const fetchedAt = new Date()
      return {
        script,
        fetchedAt,
      }
    },
    queryKey: ['snapshotland-script', scriptId],

    // TODO: revisit these
    staleTime: Infinity,
    cacheTime: 0,
  })
}

export const useSnapshotHistory = ({
  scriptId,
  from,
}: {
  scriptId: string
  from: number
}) => {
  const { apiClient, user } = useMst()
  const filter = user.prefs.snapshotFilter ?? 'all'

  return useQuery({
    queryFn: async () =>
      apiClient.fetchSnapshotHistory({
        scriptId,
        from,
        size: 20,
        filter,
      }),
    queryKey: ['snapshot-history', from, filter],
    // TODO: Revisit these. We could manually invalidate
    // if from is 0 and we discover that new snapshots exist
    // from a socket message
    staleTime: Infinity,
    cacheTime: 0,
  })
}
