import { format } from 'date-fns'
import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { FolderItem, IFolder, IListing, WorkspaceItem } from '@state/types'
import { extractTsRestSuccess } from '@util/extractTsRest'
import { SortOrder } from '@util/LocalPersistence'
import {
  sortAlphaLowerNaturalNumbering,
  toPostgresLower,
} from '@util/pgCollation'

import { BaseModel } from '../BaseModel'
import { sortAlphaByNameFn, sortByCreationFn, sortRecentFn } from '../util'

import {
  buildFolderListingsQuery,
  CursorRecord,
  isPagingStillValid,
  isPastCursor,
} from './helpers'

export const UncreatedFolder = types
  .model('UncreatedFolder', {
    name: '',
  })
  .actions((self) => ({
    setName(value: string) {
      self.name = value
    },
  }))

type FolderDetail = ZInfer<typeof schemas.FolderDetail>

export const Folder = BaseModel.named('Folder')
  .props({
    inTrash: types.boolean,
    isPrivate: types.boolean,
    id: types.identifier,
    name: types.string,
    parentId: types.string,
    uncreatedChild: types.maybe(UncreatedFolder),
    pendingLocationRequest: false,
    contents: types.maybe(
      types.model({
        scripts: types.number,
        rundowns: types.number,
        folders: types.number,
      }),
    ),
    cursorRecord: types.frozen<CursorRecord | undefined>(),
  })
  .views((self) => ({
    get isSelected(): boolean {
      return self.id === self.rootStore.view.selectedFolderId
    },
    get isRootFolder() {
      return self.id === self.parentId
    },
    get isRootTrash() {
      return this.isRootFolder && self.inTrash && !self.isPrivate
    },
    get isPrivateRootTrash() {
      return this.isRootFolder && self.inTrash && self.isPrivate
    },
    get isSharedDash(): boolean {
      return this.isRootFolder && !self.isPrivate && !self.inTrash
    },
    get displayName(): string {
      if (this.isRootTrash) return 'Trash'
      if (this.isPrivateRootTrash) return 'Private trash'
      if (this.isRootFolder) return self.isPrivate ? 'Private' : 'Shared'
      return self.name
    },
    get sortOrder(): SortOrder {
      return self.rootStore.user.getFolderSortOrder(self.id)
    },
    get unsortedDocuments(): IListing[] {
      // if this is the shared trash, get the children of any private trash root
      return self.rootStore.getDocumentsInFolder(self.id)
    },
    get sortedDocuments(): IListing[] {
      // cant reference unsortedDocuments here because mst caches
      const documents = self.rootStore.getDocumentsInFolder(self.id)
      switch (this.sortOrder) {
        case 'alphabetical':
          return documents.sort(sortAlphaByNameFn)
        case 'newest':
          return documents.sort(sortByCreationFn)
        case 'oldest':
          return documents.sort(sortByCreationFn).reverse()
        default:
          return documents.sort(sortRecentFn)
      }
    },
    get cursorFilteredDocuments(): IListing[] {
      const { cursorRecord } = self
      if (!cursorRecord) {
        return []
      }
      return this.sortedDocuments.filter(
        (item) => !isPastCursor(cursorRecord, item, this.sortOrder),
      )
    },
    get childFolders(): IFolder[] {
      const children = self.rootStore.getChildFolders(self.id)
      // if this is the shared trash root, add the private trash root
      // as the first child
      if (this.isRootTrash && !self.isPrivate) {
        const { privateTrash } = self.rootStore.rootFolders
        if (privateTrash) {
          children.unshift(privateTrash)
        }
      }
      return children
    },
    get parentFolder(): IFolder | undefined {
      return self.rootStore.folderMap.get(self.parentId)
    },
    get path(): string {
      if (this.isSharedDash) {
        return '/'
      }
      return `/folders/${self.id}`
    },
    get treeDepth(): number {
      const parent = self.rootStore.folderMap.get(self.parentId)
      // special case, we nest private root trash beneath shared trash
      if (this.isPrivateRootTrash) {
        return 1
      }
      if (!parent || parent.id === self.id) {
        return 0
      }
      return parent.treeDepth + 1
    },
    get isCreatingChild(): boolean {
      return self.rootStore.view.folderCreatingChildId === self.id
    },
    get isBeingRenamed(): boolean {
      return self.rootStore.view.folderBeingRenamedId === self.id
    },
    get documentCount(): number {
      if (!self.contents) {
        return 0
      }
      return Math.max(self.contents.scripts + self.contents.rundowns, 0)
    },
    // folders can be moved in/out of trash, but not their roots.
    // So, it's better to check up the tree than to rely on "inTrash"
    // being updated
    get belongsToTrashTree(): boolean {
      if (self.parentId === self.id) {
        return self.inTrash
      }
      return !!this.parentFolder?.inTrash
    },
  }))

  // synchronous actions
  .actions((self) => ({
    getRootFolder(isPrivate: boolean, inTrash: boolean): IFolder | undefined {
      return self.rootStore.getRootFolder(isPrivate, inTrash)
    },
    startCreatingChild() {
      self.rootStore.view.setCreatingChild(true)
      if (!self.uncreatedChild) {
        self.uncreatedChild = UncreatedFolder.create()
      }
    },
    startRenaming() {
      self.rootStore.view.startRenamingFolder(self.id)
    },
    setName(name: string) {
      self.name = name
    },
    updateFromItem({ name, parentFolderId }: FolderItem) {
      self.name = name
      self.parentId = parentFolderId
    },
    updateFromDetail({ name, parentId, contents }: FolderDetail) {
      self.name = name
      self.parentId = parentId
      self.contents = contents
    },
    updateFields(result: Partial<FolderDetail>) {
      applySnapshot(self, { ...getSnapshot(self), ...result })
    },
    setCursorRecord(item: WorkspaceItem) {
      const { id, name, contentsModifiedAt, updatedAt, createdAt } = item
      self.cursorRecord = {
        uuid: id,
        name: toPostgresLower(name),
        contentsModifiedAt,
        updatedAt,
        createdAt,
      }
    },
    clearCursorRecord() {
      self.cursorRecord = undefined
    },
  }))
  .actions((self) => ({
    setPendingLocationRequest(value: boolean) {
      self.pendingLocationRequest = value
    },
    async createRundown(
      name = `Rundown ${format(new Date(), 'MM/dd/yyyy')}`,
    ): Promise<{ rundownId: number }> {
      const params = { body: { name, folderId: self.id } }

      const {
        body: { id: rundownId },
      } = await extractTsRestSuccess(self.scrapi.rundowns.create(params), 200)

      const rowCount = 10
      const rowDataList: ZInfer<typeof schemas.RowDataInput>[] = []
      for (let i = 0; i < rowCount; i++) {
        rowDataList.push({ rowTypeId: 'element', blobData: {} })
      }

      await extractTsRestSuccess(
        self.legacyApi.insertRundownRows({
          params: { id: rundownId },
          body: {
            sequence: 1,
            rowDataList,
          },
        }),
        200,
      )
      return { rundownId }
    },

    async createSubfolder({ name }: { name: string }) {
      const result = await self.scrapi.folders.create({
        body: {
          name,
          parentId: self.id,
        },
      })

      if (result.status === 201) {
        self.rootStore.ingestFolderList([result.body])
      }
      self.rootStore.view.setCreatingChild(false)
    },

    async importScriptFromFdx(file: File): Promise<{ id: string } | undefined> {
      const result = await self.apiClient.importFdx({
        folderId: self.id,
        file,
        formatId: self.rootStore.currentOrg?.screenplayFormat.id ?? '',
      })
      return result[0]
    },

    async moveToTrash() {
      const { message, error } = await self.apiClient.trashFolder({
        folderId: self.id,
      })
      // optimistically update the folderMap on success
      const parentId = self.rootStore.rootFolders.sharedTrash?.id
      if (parentId && message && error === undefined) {
        self.updateFields({
          inTrash: true,
          parentId,
          name: self.name,
          isPrivate: self.isPrivate,
        })
      }
    },

    async moveToFolder(parentId: string) {
      const { isPrivate, inTrash, name } = await self.apiClient.updateFolder({
        folderId: self.id,
        parentId,
      })
      self.updateFields({
        isPrivate,
        inTrash,
        name,
        parentId,
      })
    },

    // this looks at the folder's sort order and cursor
    // and loads the next page of documents, ingests them,
    // then updates the cursor if it's still appropriate. We
    // use react-query for this so that multiple views/requests
    // for the same key can share promises/cached responses
    async loadPageOfDocuments() {
      const pageSize = self.rootStore.view.listingsPageSize
      const { sortOrder, cursorRecord } = self
      const initialSortOrder = sortOrder

      const { queryFn, queryKey, paging } = buildFolderListingsQuery({
        folderId: self.id,
        scrapi: self.scrapi,
        pageSize,
        sortOrder,
        cursorRecord,
      })

      const result = await self.environment.queryClient.fetchQuery({
        queryFn,
        queryKey,
        staleTime: Infinity,
        cacheTime: Infinity,
      })

      if (result.status !== 200) {
        return {
          success: false,
          result,
        }
      }
      // now we've got listings to load no matter what. The cursor
      // record MIGHT be stale though, so we check
      const shouldUpdateCursor =
        // the sort order should be the same
        initialSortOrder === self.sortOrder &&
        // And we shouldn't have invalidated the cursor in the
        // meantime
        isPagingStillValid({
          currentSortOrder: self.sortOrder,
          currentCursor: self.cursorRecord,
          fetchedPaging: paging,
        })

      self.rootStore.ingestWorkspaceItems(result.body.data)
      if (shouldUpdateCursor) {
        const lastRecord = result.body.data[result.body.data.length - 1]
        if (lastRecord) {
          self.setCursorRecord(lastRecord)
        } else {
          // TODO: we should probably check that our cursor/counts
          // are correct. This might mean the user is seeing a defunct
          // "LOAD MORE" button.
        }
      }
      return {
        success: true,
        data: result.body.data,
      }
    },

    setSortOrder(order: SortOrder) {
      if (order !== self.sortOrder) {
        self.rootStore.user.setFolderSortOrder(self.id, order)
        self.clearCursorRecord()
        this.loadPageOfDocuments()
      }
    },

    async rename(name: string) {
      await self.apiClient.updateFolder({
        folderId: self.id,
        name,
      })
      self.setName(name)
      self.rootStore.view.stopRenamingFolder()
    },

    async refreshDetails() {
      const response = await self.scrapi.folders.getSummary({
        params: { id: self.id },
      })
      if (response.status === 200) {
        self.updateFromDetail(response.body)
      }
    },
  }))

export const sortFoldersAlphaFn = (
  a: Instance<typeof Folder>,
  b: Instance<typeof Folder>,
): number => {
  const diff = sortAlphaLowerNaturalNumbering(a.displayName, b.displayName)
  if (diff === 0) {
    return a.id < b.id ? -1 : 1
  }

  return diff
}
