import React from 'react'

import { ActionIcon, Group, Switch, TextInput } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { useDragLayer } from 'react-dnd'

import { FaIcon } from '@components/FaIcon'
import { useMst } from '@state'
import { ILoadedScript } from '@state/types'
import { NavLinkElement } from '@util/constants'
import { scrollToBlock } from '@util/scrolling'

import { EmptyOutline, NoFilterMatch } from './EmptyOutline'
import { canPlaceAbove, findCurrentNavLink, isDraggableType } from './helpers'
import { NavLink } from './NavLink'

import styles from './DocumentOutline.module.scss'

export const DocumentOutline = observer(function DocumentOutline({
  script,
  hidden,
  allowLinkDragging,
}: {
  script: ILoadedScript
  allowLinkDragging?: boolean
  hidden?: boolean
}) {
  const isScreenplay = script.type === 'screenplay'
  const { user } = useMst()
  const [filter, setFilter] = React.useState('')
  const [showBrackets, setShowBrackets] = React.useState(
    user.prefs.bracketsInNav,
  )

  // any time the script changes, we should reset the filter
  React.useEffect(() => {
    setFilter('')
  }, [script.id])

  const toggleBrackets = () => {
    user.updatePreferences({ bracketsInNav: !showBrackets })
    setShowBrackets(!showBrackets)
  }

  // filter the list of nav links based on the bracket toggle
  const hiddenLinkTypes: NavLinkElement[] = showBrackets
    ? ['end_of_act']
    : ['end_of_act', 'bracket']

  const relevantNavLinks = script.pmEditor.navLinks.filter(
    (n) => !hiddenLinkTypes.includes(n.type),
  )

  const visibleNavLinks = relevantNavLinks.filter((n) =>
    n.text.toLowerCase().includes(filter.trim().toLowerCase()),
  )

  const cursorPos = script.pmEditor.selection?.$from.pos
  const currentNavLink = cursorPos
    ? findCurrentNavLink({
        visibleNavLinks,
        cursorPos,
      })
    : null

  const showEmptyOutline = relevantNavLinks.length === 0
  const showNoFilterMatch = !showEmptyOutline && visibleNavLinks.length === 0

  // Set up a drag layer that will monitor if a nav link
  // is being drag and if so what its ID is. This lets us know
  // which drop targets to activate
  const { navLinkIsBeingDragged, item } = useDragLayer<{
    navLinkIsBeingDragged: boolean
    item: { id: string }
  }>((monitor) => ({
    navLinkIsBeingDragged:
      monitor.isDragging() && monitor.getItemType() === 'nav-link',
    item: monitor.getItem(),
  }))

  // to preserve the ui state, we keep the outline component even if it's
  // not visible and just toggle the style
  const style = hidden ? { display: 'none' } : {}

  const closeButton =
    filter.length > 0 ? (
      <ActionIcon onClick={() => setFilter('')}>
        <FaIcon color="dark.9" icon="fa-xmark" size="14" />
      </ActionIcon>
    ) : null

  return (
    <div className={styles.documentOutline} style={style}>
      {!showEmptyOutline && (
        <TextInput
          p={10}
          size="sm"
          leftSection={<FaIcon icon="fa-magnifying-glass" fz={12} />}
          placeholder="Filter"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          rightSection={closeButton}
        />
      )}
      <div className={styles.documentOutline_scroll}>
        {showEmptyOutline && <EmptyOutline isScreenplay={isScreenplay} />}
        {showNoFilterMatch && <NoFilterMatch filterText={filter} />}
        {visibleNavLinks.map((navLink, index) => {
          const draggable =
            allowLinkDragging && !showBrackets && isDraggableType(navLink)

          // determine if this item render drop target above itself
          const canDropAbove =
            allowLinkDragging &&
            navLinkIsBeingDragged &&
            canPlaceAbove({
              links: visibleNavLinks,
              movingId: item.id,
              targetId: navLink.id,
            })

          // determine if item should render a drop target below itself
          // (only happens for the last link)
          const showFinalDropTarget =
            allowLinkDragging &&
            navLinkIsBeingDragged &&
            navLink.id !== item.id &&
            index === visibleNavLinks.length - 1

          const cursorPlacement =
            currentNavLink?.link.id !== navLink.id
              ? undefined
              : currentNavLink?.placement

          return (
            <NavLink
              // NOTE: we're use draggable in the key to destroy/recreate the component instance
              // when the draggability changes. React-dnd memoizes in a way that makes this
              // preferable to handling inside
              key={navLink.id + String(draggable)}
              link={navLink}
              draggable={draggable}
              canDropAbove={canDropAbove}
              showFinalDropTarget={showFinalDropTarget}
              cursorPlacement={cursorPlacement}
              onClick={() => {
                scrollToBlock(navLink.id)
                script.pmEditor.selectPosition(navLink.pos)
              }}
            />
          )
        })}
      </div>
      {!isScreenplay && (
        <Group wrap="nowrap" className={styles.documentOutline_footer}>
          <Switch
            size="xs"
            label="Show Brackets"
            checked={showBrackets}
            onChange={toggleBrackets}
          />
        </Group>
      )}
    </div>
  )
})
