import React from 'react'

import { ColumnState, IRowNode } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'

import { util as codexUtil } from '@showrunner/codex'
import { schemas, ZInfer } from '@showrunner/scrapi'

import { schemaColumnToColDef } from '@components/RundownGrid/columns/columnTypes'
import { IRundownRow, ScriptReference } from '@state/types'
import { notEmptyFilter } from '@util'
import { extractTsRestSuccess } from '@util/extractTsRest'
import { RundownColumnState, RundownLayoutType } from '@util/LocalPersistence'
import { rowDataFromScript } from '@util/rundownImport'
import {
  CONTROLS_COLUMN_ID,
  EnrichedRowData,
  GridBlobColumnKey,
  isClipboardCurrent,
  mapWithDurations,
  ROW_LEVEL_BLOB_FIELD,
  RundownSchema,
  stripBlobKeyPrefix,
} from '@util/rundowns'
import {
  BlobData,
  RundownPayload,
  RundownRowData,
} from '@util/ScriptoApiClient/types'

import { RundownListingBase } from '../ListingBase'
import { PrompterPushCandidate } from '../PrompterPushCandidate'
import { RundownEventPayload } from '../SocketManager/helpers'

import {
  clearFocusedCell,
  findFocusedRowId,
  FocusedCellData,
  setFocusedCell,
} from './grid-helpers'
import { RundownBlobData } from './RundownBlobData'
import { RundownRow } from './RundownRow'

type HashableRows = Parameters<typeof codexUtil.hashRundownRows>[0]
type InsertRowsRequest = ZInfer<typeof schemas.InsertRowsRequest>
type InsertRowsResponse = ZInfer<typeof schemas.InsertRowsResponse>

// This is to replace an enum in ag-grid-community that jest cannot
// import without barfing.
export const Rundown = RundownListingBase.named('Rundown')
  .props({
    id: types.number,
    rowMap: types.map(RundownRow),
    selectedRowIds: types.array(types.number),

    // tracks when we are loading or updating data via the API
    gridIsLoading: false,
    // tracks when the user is actively editing a cell in AGGrid
    gridIsEditing: false,

    blobData: RundownBlobData,
    prompterPushCandidate: types.maybe(PrompterPushCandidate),
    focusedRow: types.safeReference(RundownRow),
    schema: types.frozen<RundownPayload['schema']>(),

    // local storage-persisted values settings
    screenColumnPrefs: types.frozen<RundownColumnState>({}),
    printColumnPrefs: types.frozen<RundownColumnState>({}),
    // Pending socket events that need to be applied at the next
    // opportunity (when we're not loading data/editing/etc)
    pendingRowMessages: types.array(types.frozen<RundownEventPayload>()),
  })
  .volatile<{
    gridRef: React.RefObject<AgGridReact<EnrichedRowData>>
    printableGridRef: React.RefObject<AgGridReact>
    pendingFocus?: FocusedCellData
  }>(() => ({
    gridRef: React.createRef<AgGridReact>(),
    printableGridRef: React.createRef<AgGridReact>(),
  }))
  .views((self) => ({
    get path() {
      return `/rundowns/${self.id}`
    },
    get sortedRowInstances() {
      return Array.from(self.rowMap.values()).sort(
        (r1, r2) => r1.sequence - r2.sequence,
      )
    },
    getRowNode(rowId: string | number): IRowNode<RundownRowData> | undefined {
      return self.gridRef.current?.api.getRowNode(String(rowId))
    },
    get gridApi(): AgGridReact['api'] | undefined {
      return self.gridRef.current?.api
    },
    // For AG grid and for checksumming
    get immutableRowData(): RundownRowData[] {
      return Array.from(self.rowMap.values())
        .sort((r1, r2) => r1.sequence - r2.sequence)
        .map((rd) => rd.pojo)
    },
    get rowDataForGrid(): EnrichedRowData[] {
      return mapWithDurations(this.immutableRowData, self.schema.schema.columns)
    },
    getTotalDurationForColumn(colId: string): number | undefined {
      const lastRow: EnrichedRowData | undefined =
        this.rowDataForGrid[this.rowDataForGrid.length - 1]
      return lastRow?.durations[colId]
    },
    get totalRuntime() {
      const primaryDurationField = self.schema.schema.primaryDurationField
      if (primaryDurationField) {
        return this.getTotalDurationForColumn(
          stripBlobKeyPrefix(primaryDurationField),
        )
      }
    },
    get checksum(): string {
      return codexUtil.hashRundownRows(this.immutableRowData as HashableRows)
    },
    checksumMatches(checksum: string) {
      return this.checksum === checksum
    },
    getRow(rowId: number): Instance<typeof RundownRow> | undefined {
      return self.rowMap.get(String(rowId))
    },
    isRowSelected(rowId: number): boolean {
      return self.selectedRowIds.includes(rowId)
    },
    get durationColumns() {
      return self.schema.schema.columns.filter(
        (c) => c.rundownColumnType === 'duration',
      )
    },
    get firstRow(): Instance<typeof RundownRow> | undefined {
      return this.sortedRowInstances[0]
    },
    get lastRow(): Instance<typeof RundownRow> | undefined {
      return this.sortedRowInstances[this.sortedRowInstances.length - 1]
    },
    findScriptRow(scriptId: string): Instance<typeof RundownRow> | undefined {
      return this.sortedRowInstances.find(
        (r) => r.identityScriptId === scriptId,
      )
    },
    get sortedSelectedRows(): IRundownRow[] {
      return this.sortedRowInstances.filter((r) => r.selectedInGrid)
    },
    get hasNumberedRows(): boolean {
      return this.sortedRowInstances.some((row) =>
        row.getBlobValue('blobData.itemNumber'),
      )
    },
    get selectionHasNumberedRows(): boolean {
      return this.sortedSelectedRows.some((row) =>
        row.getBlobValue('blobData.itemNumber'),
      )
    },
    get selectionSummary(): { rows: IRundownRow[]; continuous: boolean } {
      const rows: IRundownRow[] =
        this.sortedSelectedRows.length > 0
          ? this.sortedSelectedRows
          : self.focusedRow
            ? [self.focusedRow]
            : []

      const rowWithGap = this.sortedSelectedRows.find((row, index) => {
        const previousSelectedRow = this.sortedSelectedRows[index - 1]
        return (
          !!previousSelectedRow &&
          previousSelectedRow.sequence !== row.sequence - 1
        )
      })

      return {
        rows,
        continuous: !rowWithGap,
      }
    },
    getChildRows(rowId: number): IRundownRow[] {
      const rowIndex = this.sortedRowInstances.findIndex((r) => r.id === rowId)
      const row = this.sortedRowInstances[rowIndex]
      const rowLevel = row?.rowLevel
      if (!row || rowLevel === undefined) {
        return []
      }
      const result: IRundownRow[] = []
      for (let i = rowIndex + 1; i < this.sortedRowInstances.length; i++) {
        const nextRow = this.sortedRowInstances[i]
        if (!nextRow) {
          break
        }
        if (nextRow.rowLevel && nextRow.rowLevel <= rowLevel) {
          break
        }
        result.push(nextRow)
      }

      return result
    },
    get orderedScripts(): ScriptReference[] {
      const scriptColumnId = self.schema.schema.importRules.script.columnId
      const result: Array<{ scriptId: string; name: string }> = []
      this.sortedRowInstances.forEach((r) => {
        if (r.identityScriptId) {
          const rawName = r.blobData.get(scriptColumnId)
          const name = typeof rawName === 'string' ? rawName : 'Script'

          result.push({
            scriptId: r.identityScriptId,
            name,
          })
        }
      })
      return result
    },
    get pasteableRows(): RundownRowData[] {
      const clipboard =
        self.rootStore.environment.localPersistence.getRundownClipboard(
          self.rootStore.user.id,
        )
      if (clipboard) {
        const { rows, copiedAt } = clipboard
        if (isClipboardCurrent(new Date(copiedAt))) {
          return rows
        }
      }
      return []
    },
    get hasTrackedTiming(): boolean {
      return self.blobData.episodeLengthSeconds > 0
    },
    getConfigurableColumnDefs(layout: RundownLayoutType) {
      const savedColumnState =
        layout === 'screen' ? self.screenColumnPrefs : self.printColumnPrefs

      return self.schema.schema.columns.map((schemaColumn) => {
        const colDef = schemaColumnToColDef(schemaColumn)
        const { hide, width } = savedColumnState[colDef.colId] ?? {}
        if (hide) {
          colDef.hide = true
        }
        if (typeof width === 'number') {
          colDef.width = width
        }
        return colDef
      })
    },
    get hiddenScreenColumnCount() {
      return this.getConfigurableColumnDefs('screen').filter((cd) => cd.hide)
        .length
    },
    get isEditable(): boolean {
      return !self.inTrash && self.rootStore.user.canEditRundowns
    },
  }))

  .actions((self) => ({
    setRowSelected(id: number, value: boolean) {
      const gridNode = self.getRowNode(id)
      if (gridNode) {
        self.gridApi?.setNodesSelected({
          nodes: [gridNode],
          newValue: value,
        })
      }

      if (value) {
        if (!self.selectedRowIds.includes(id)) {
          self.selectedRowIds.push(id)
        }
      } else {
        self.selectedRowIds.remove(id)
      }
    },
    toggleRowSelected(id: number) {
      const isSelected = self.selectedRowIds.includes(id)
      this.setRowSelected(id, !isSelected)
    },

    selectRows(rowIds: number[]) {
      rowIds.forEach((id) => this.setRowSelected(id, true))
    },

    trackModified() {
      self.analytics.trackDocModified('rundown', self.id)
    },
    prepareForRowManipulation() {
      // when we insert or delete rows programmatically, we need to cancel the focused selection
      // otherwise when the rows change, the focus will remain on the same index which will be the
      // wrong target, but we save off the data so that after the row manipulation
      // is complete we can re-apply the focus to the right spot
      if (self.gridRef.current) {
        self.pendingFocus = clearFocusedCell(self.gridRef.current.api)
      }
    },
    restoreFocus() {
      if (self.gridRef.current && self.pendingFocus) {
        setFocusedCell(self.pendingFocus, self.gridRef.current.api)
        self.pendingFocus = undefined
      }
    },
    clearRows() {
      self.rowMap.clear()
    },
    saveColumnState(colPrefs: ColumnState[], layout: RundownLayoutType) {
      const columnState: RundownColumnState = {}
      colPrefs.forEach(({ colId, hide, width }) => {
        if (colId !== CONTROLS_COLUMN_ID) {
          columnState[colId] = { hide, width }
        }
      })
      if (layout === 'screen') {
        self.screenColumnPrefs = columnState
      } else {
        self.printColumnPrefs = columnState
      }
      self.environment.localPersistence.setRundownColumnState({
        userId: self.rootStore.user.id,
        schemaId: self.schema.name,
        layout,
        columnState,
      })
    },
    packLocalSequences() {
      self.sortedRowInstances.forEach((row, index) => {
        row.setSequence(index + 1)
      })
    },
    makeRoomForRows(targetSequence: number, size: number) {
      Array.from(self.rowMap.values()).forEach((row) => {
        if (row.sequence >= targetSequence) {
          row.setSequence(row.sequence + size)
        }
      })
    },
    ingestLocalRows(rowData: RundownRowData[]) {
      if (rowData.length === 0) {
        return
      }
      this.prepareForRowManipulation()
      const insertionSequence = rowData[0].sequence
      // move all rows in the range higher so we can insert at the target sequence
      this.makeRoomForRows(insertionSequence, rowData.length)

      // now insert the rows
      rowData.forEach((rd) => {
        // todo: get rid of this destructuring after we stop using aggridhelpers copy of the data
        self.rowMap.put({ ...rd, blobData: { ...rd.blobData } })
      })
      // now pack sequences
      this.packLocalSequences()
    },
    removeLocalRows(rowIds: number[]) {
      this.prepareForRowManipulation()
      rowIds.forEach((rid) => self.rowMap.delete(String(rid)))
      this.packLocalSequences()
    },
    moveLocalRows(rowIds: number[], targetSequence: number) {
      this.prepareForRowManipulation()
      this.makeRoomForRows(targetSequence, rowIds.length)
      rowIds.forEach((rowId, index) => {
        self.getRow(rowId)?.setSequence(targetSequence + index)
      })
      this.packLocalSequences()
    },
    applyBlobUpdates({
      delta,
    }: ZInfer<typeof schemas.sockets.RUNDOWN_ROW_BLOBS_UPDATED>) {
      const { key, rowValues } = delta
      rowValues.forEach(({ rowId, value }) => {
        const row = self.getRow(rowId)
        row?.updateValue(`blobData.${key}`, value)
      })
    },
    createPushCandidate() {
      const candidate = PrompterPushCandidate.create({
        parentId: self.id,
        parentType: 'rundown',
      })
      self.prompterPushCandidate = candidate
      return candidate
    },
    clearPushCandidate() {
      self.prompterPushCandidate = undefined
    },
    updateFocusedRow() {
      const api = self.gridRef.current?.api
      if (api) {
        const rowId = findFocusedRowId(api)
        if (typeof rowId === 'number') {
          self.focusedRow = self.getRow(rowId)
          return
        }
      }
      self.focusedRow = undefined
    },
    setPreviewSchema(schema: RundownSchema) {
      self.schema = {
        id: -1,
        name: 'Preview',
        description: 'Preview',
        schema,
      }
    },
    copyRows() {
      const rows = self.sortedSelectedRows.map((r) => r.pojo)
      const data = {
        rows,
        schemaId: self.schema.id,
        copiedAt: new Date().toISOString(),
      }
      self.environment.localPersistence.setRundownClipboard(
        self.rootStore.user.id,
        data,
      )
    },
  }))

  // special actions for working with sockets
  .actions((self) => ({
    applySocketChange(payload: RundownEventPayload) {
      switch (payload.eventType) {
        case 'RUNDOWN_ROWS_INSERTED': {
          self.ingestLocalRows(payload.data)
          break
        }
        case 'RUNDOWN_ROWS_DELETED':
          self.removeLocalRows(payload.delta.rowIds)
          break
        case 'RUNDOWN_ROWS_MOVED':
          self.moveLocalRows(payload.delta.rowIds, payload.delta.sequence)
          break
        case 'RUNDOWN_ROW_BLOBS_UPDATED':
          self.applyBlobUpdates(payload)
          break
      }
    },

    // usually we call setGridLoading but that has side effects. When
    // we're doing a reload, we need to control this directly so we don't
    // get an infinite loop
    setLoadingInternal(value: boolean) {
      self.gridIsLoading = value
    },
    clearPendingQueue() {
      self.pendingRowMessages.clear()
    },
    processPendingQueue() {
      // bail if no changes
      if (self.pendingRowMessages.length === 0) {
        return
      }

      // check if we're already in sync (this happens when we receive
      // events of our own changes)
      const lastMessage: RundownEventPayload =
        self.pendingRowMessages[self.pendingRowMessages.length - 1]
      const targetChecksum = lastMessage.checksum
      if (self.checksumMatches(targetChecksum)) {
        self.pendingRowMessages.clear()
        return
      }

      try {
        let nextMessage: RundownEventPayload | undefined
        while ((nextMessage = self.pendingRowMessages.shift())) {
          this.applySocketChange(nextMessage)
        }
      } catch (e) {
        self.log.error('Error applying socket message', { topic: 'sockets' }, e)
      }

      if (!self.checksumMatches(targetChecksum)) {
        self.log.error('Checksum mismatch after socket event applied', {
          topic: 'sockets',
        })
        this.reloadData()
      }
    },
    refreshColumnPrefs(layout: RundownLayoutType) {
      // load prefs for this rundown schema from local storage
      const { id: userId } = self.rootStore.user
      const colPrefs = self.environment.localPersistence.getRundownColumnState({
        userId,
        schemaId: self.schema.name,
        layout,
      })

      if (layout === 'screen') {
        self.screenColumnPrefs = colPrefs
      } else {
        self.printColumnPrefs = colPrefs
      }
    },

    // This forces a full refresh of all grid data from the server and
    // replaces the ag grid's rowData entirely. We use this if we're out of
    // sync or if we get an error on a grid API call
    async reloadData() {
      this.setLoadingInternal(true)
      try {
        const result = await self.apiClient.getRundown(self.id)
        self.clearRows()
        applySnapshot(self, { ...getSnapshot(self), ...result })
        self.ingestLocalRows(result.rows)
        // we are in sync so any delta updates are irrelevant
        this.clearPendingQueue()
      } catch (e) {
        self.log.error('Error reloading rundown data', { topic: 'sockets' }, e)
      } finally {
        this.setLoadingInternal(false)
      }
    },
  }))

  // sync actions
  .actions((self) => ({
    setName(name: string) {
      self.name = name
    },
    setGridEditing(value: boolean) {
      self.gridIsEditing = value
      if (value) {
        this.deselectAllRows()
      } else {
        this.processPendingIfAppropriate()
      }
    },
    setGridLoading(value: boolean) {
      if (self.gridIsLoading === value) {
        return
      }

      // this observable can be used for internal logic
      // or ReactUI
      self.setLoadingInternal(value)

      // if we move from loading true to false, see if we have any
      // pending socket messages we need to apply
      this.processPendingIfAppropriate()
    },
    setBlobData(blob: Instance<typeof RundownBlobData>) {
      self.blobData = blob
    },
    deselectAllRows() {
      self.selectedRowIds.replace([])
      self.gridApi?.deselectAll()
    },
    selectAllRows() {
      const ids = Array.from(self.rowMap.values()).map((r) => r.id)
      self.selectedRowIds.replace(ids)
      self.gridApi?.selectAll()
    },
    replaceSelectedRows(rows: IRundownRow[]) {
      const ids = rows.map((r) => r.id)
      self.selectedRowIds.replace(ids)
      const nodes = ids.map((id) => self.getRowNode(id)).filter(notEmptyFilter)
      self.gridApi?.deselectAll()
      self.gridApi?.setNodesSelected({
        nodes,
        newValue: true,
      })
    },
    processPendingIfAppropriate() {
      if (
        !(self.gridIsLoading || self.gridIsEditing) &&
        self.pendingRowMessages.length > 0
      ) {
        self.processPendingQueue()
      }
    },
  }))

  // async actions
  .actions((self) => ({
    async updateRowBlobData({
      rowId,
      columnKey,
      value,
    }: {
      rowId: number
      columnKey: GridBlobColumnKey
      value: JSONPrimitive
    }) {
      const row = self.getRow(rowId)
      if (row) {
        await extractTsRestSuccess(
          self.scrapi.rundowns.updateRowBlobData({
            params: { id: self.id },
            body: {
              key: stripBlobKeyPrefix(columnKey),
              rowValues: [
                {
                  rowId,
                  value,
                },
              ],
            },
          }),
          200,
        )
        self.trackModified()
      }
    },

    async updateBlobData(newBlob: Instance<typeof RundownBlobData>) {
      const params = {
        params: { id: self.id },
        body: { blobData: newBlob.forServer() },
      }
      await extractTsRestSuccess(
        self.scrapi.rundowns.updateRundown(params),
        200,
      )
      self.setBlobData(newBlob)
      self.trackModified()
    },

    async updateEpisodeLength(newValue: number) {
      if (newValue === self.blobData.episodeLengthSeconds) {
        return
      }
      const newBlob = RundownBlobData.create({
        ...getSnapshot(self.blobData),
        episodeLengthSeconds: newValue,
      })
      await this.updateBlobData(newBlob)
      self.blobData.setEpisodeLength(newValue)
    },

    async updateStartTimeAndLength(
      startTime: Date,
      episodeLengthSeconds: number,
    ) {
      const newBlob = RundownBlobData.create({
        ...getSnapshot(self.blobData),
        startTime: startTime,
        episodeLengthSeconds,
      })
      await this.updateBlobData(newBlob)
      self.blobData.setStartTime(startTime)
    },

    async moveRowsToPosition({
      rowIds,
      sequence,
    }: {
      rowIds: number[]
      sequence: number
    }) {
      const params = {
        params: { id: self.id },
        body: {
          sequence,
          rowIds,
        },
      }

      await extractTsRestSuccess(self.scrapi.rundowns.moveRows(params), 200)
      self.moveLocalRows(rowIds, sequence)
      self.trackModified()
    },

    async insertRows(options: InsertRowsRequest): Promise<InsertRowsResponse> {
      // short-term reverse flag just in case...
      const useLegacy = self.rootStore.view.isDebugOrFlagEnabled('legacy-rows')
      const fn = useLegacy
        ? self.legacyApi.insertRundownRows
        : self.scrapi.rundowns.insertRundownRows
      const result = await extractTsRestSuccess(
        fn({ params: { id: self.id }, body: options }),
        200,
      )
      return result.body
    },

    async insertScriptRow({
      scriptId,
      sequence,
      name,
    }: {
      scriptId: string
      sequence: number
      name: string
    }) {
      const existingRowNode = self.findScriptRow(scriptId)
      const { columnId, rowLevel } = self.schema.schema.importRules.script
      if (!existingRowNode) {
        const blobData: BlobData = {
          [columnId]: name,
        }
        if (rowLevel) {
          blobData[ROW_LEVEL_BLOB_FIELD] = rowLevel
        }

        const { data } = await this.insertRows({
          sequence,
          rowDataList: [
            {
              rowTypeId: 'script',
              identityScriptId: scriptId,
              blobData,
            },
          ],
        })
        if (data && data.length > 0) {
          self.ingestLocalRows(data)
        }
        self.trackModified()
      }
    },

    async insertBlankRows({
      sequence,
      rowCount,
      rowTypeId,
      rowLevel,
    }: {
      sequence: number
      rowCount: number
      rowTypeId: 'element' | 'header'
      rowLevel?: 1 | 2 | 3
    }) {
      const rowDataList: InsertRowsRequest['rowDataList'] = []
      const blobData: BlobData = {}
      if (rowLevel) {
        blobData[ROW_LEVEL_BLOB_FIELD] = rowLevel
      }
      for (let i = 0; i < rowCount; i++) {
        rowDataList.push({
          rowTypeId,
          blobData,
        })
      }

      const { data } = await this.insertRows({
        rowDataList,
        sequence,
      })
      self.ingestLocalRows(data)
    },

    async removeRows(rowIds: number[]) {
      const params = {
        params: { id: self.id },
        body: {
          rowIds,
        },
      }
      await extractTsRestSuccess(self.scrapi.rundowns.removeRows(params), 200)

      self.removeLocalRows(rowIds)
      if (self.rowMap.size === 0) {
        await this.insertBlankRows({
          rowTypeId: 'element',
          rowCount: 10,
          sequence: 1,
        })
      }
      self.trackModified()
    },

    async setItemNumbers(
      rowValues: Array<{
        rowId: number
        value: string
      }>,
    ) {
      await extractTsRestSuccess(
        self.scrapi.rundowns.updateRowBlobData({
          params: { id: self.id },
          body: {
            key: 'itemNumber',
            rowValues,
          },
        }),
        200,
      )
      rowValues.forEach(({ rowId, value }) => {
        self.getRow(rowId)?.updateBlobValue('itemNumber', value)
      })
      self.trackModified()
    },

    async updateName(name: string) {
      const params = {
        params: { id: self.id },
        body: { name },
      }
      await extractTsRestSuccess(
        self.scrapi.rundowns.updateRundown(params),
        200,
      )
      self.setName(name)
    },

    async importRows({
      scriptId,
      sequence,
    }: {
      scriptId: string
      sequence: number
    }) {
      const payload = await self.apiClient.getScript(scriptId)
      const rowDataList = rowDataFromScript({
        payload,
        schema: self.schema.schema,
      })

      const { data } = await this.insertRows({
        rowDataList,
        sequence,
      })
      self.ingestLocalRows(data)
      self.trackModified()
    },

    async insertRowsFromClipboard() {
      const rows = self.pasteableRows
      if (rows.length === 0) {
        return
      }
      const sequence = self.selectionSummary.rows[0]?.sequence ?? 1
      const rowDataList: InsertRowsRequest['rowDataList'] = rows.map(
        ({ id, rundownId, sequence, ...rest }) => rest,
      )

      const { data } = await this.insertRows({
        sequence,
        rowDataList,
      })
      self.ingestLocalRows(data)
      self.trackModified()
    },

    handleSocketMessage(payload: RundownEventPayload) {
      self.pendingRowMessages.push(payload)
      self.processPendingIfAppropriate()
    },

    afterAttach() {
      self.rootStore.socketManager.joinRundown(self.id)
      self.refreshColumnPrefs('screen')
      self.refreshColumnPrefs('print')
    },

    // Clean up listeners when this model is destroyed/removed from the tree
    beforeDetach() {
      self.pendingRowMessages.clear()
      self.rootStore.socketManager.leaveRundown(self.id)
    },
  }))

// helper function so we can convert the array from the server into a map
// which is a bit friendlier for mst data lookups, etc.
export const createRundownInstance = (
  data: RundownPayload,
): Instance<typeof Rundown> => {
  const instance = Rundown.create(data)
  instance.ingestLocalRows(data.rows)
  return instance
}
