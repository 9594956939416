import { z } from 'zod'

import { schemas, ZInfer } from '@showrunner/scrapi'

const { StandardBlockConfig } = schemas.scriptFormats

export type ScriptFormatConfiguration = ZInfer<
  typeof schemas.scriptFormats.ScriptFormatDefinition
>

export type ScriptFormatBlockConfiguration = ScriptFormatConfiguration['blocks']
export type BlockFormats = ScriptFormatBlockConfiguration

export type FormatBlockName = keyof ScriptFormatBlockConfiguration

const formatKeyEnum = schemas.scriptFormats.StandardBlockConfig.keyof()
export type FormatOption = ZInfer<typeof formatKeyEnum>

export type FormatOptionValue<T extends FormatOption> = ZInfer<
  typeof schemas.scriptFormats.StandardBlockConfig
>[T]

const standardBlockOverride = StandardBlockConfig.partial()
  .optional()
  // if there's an error in the overrides, just set the thing to undefined
  .catch(undefined)
export type SingleBlockOverride = z.infer<typeof standardBlockOverride>

const standardBlockDefinedOverride = StandardBlockConfig.partial()
  // if there's an error in the overrides, set an empty object
  .catch({})

export type DefinedSingleBlockOverride = z.infer<
  typeof standardBlockDefinedOverride
>

export const blockOverridesParser = z
  .object({
    general: standardBlockOverride,
    new_act: standardBlockOverride,
    end_of_act: standardBlockOverride,
    action: standardBlockOverride,
    sceneHeading: standardBlockOverride,
    transition: standardBlockOverride,
    slug: standardBlockOverride,
    bracket: standardBlockOverride,
    dialogue: standardBlockOverride,
    character: standardBlockOverride,
    parenthetical: standardBlockOverride,
    dual_dialogue: standardBlockOverride,
    dual_dialogue_column: standardBlockOverride,
  })
  .partial()

export type BlockOverrides = z.infer<typeof blockOverridesParser>

export const parseOverrides = (input: unknown): BlockOverrides => {
  if (typeof input === 'object' && !!input) {
    const parsed = blockOverridesParser.safeParse(input)
    if (parsed.success) {
      return parsed.data
    }
  }
  return {}
}

// strip away any overrides for a single block that match the
// base format
export const extractDifferences = (
  baseConfig: z.infer<typeof StandardBlockConfig>,
  override: SingleBlockOverride,
): SingleBlockOverride | undefined => {
  if (!override) {
    return undefined
  }
  const result: SingleBlockOverride = {}
  StandardBlockConfig.keyof().options.forEach((key) => {
    const newValue = override[key]
    const baseValue = baseConfig[key]
    if (newValue !== baseValue && newValue !== undefined && newValue !== '') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result[key] = newValue as any
    }
  })
  return Object.keys(result).length > 0 ? result : undefined
}

export const minifyOverrides = (
  baseFormat: BlockFormats,
  overrides: BlockOverrides,
): BlockOverrides => {
  const result: BlockOverrides = {}
  blockOverridesParser.keyof().options.forEach((key) => {
    const diffs = extractDifferences(baseFormat[key], overrides[key])
    if (diffs) {
      result[key] = diffs
    }
  })
  return result
}
