import { FindListingsPayload } from '../types'

import { ApiConfig } from './types'

export const getRecentListings: ApiConfig<
  { orgId: string },
  FindListingsPayload
> = ({ orgId }) => ({
  method: 'GET',
  url: `/orgs/${orgId}/listings/latest`,
})

export const getListingsById: ApiConfig<
  {
    orgId: string
    rundownIds: number[]
    scriptIds: string[]
  },
  FindListingsPayload
> = ({ orgId, rundownIds, scriptIds }) => ({
  method: 'POST',
  url: `/orgs/${orgId}/listings/find`,
  data: {
    rundownIds,
    scriptIds,
  },
})
