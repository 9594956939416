import { Divider, Group, Stack, Text, Tooltip } from '@mantine/core'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useRoute } from 'wouter'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { ArchivedScriptIcon } from '@components/ArchivedScript'
import { FaIcon, FaIconName, ScriptStatusIcon } from '@components/FaIcon'
import { NavAnchor, NavAnchorHref } from '@components/NavAnchor'
import { getDateFormatting, useNavigation } from '@hooks'
import { useMst } from '@state'
import { capitalize, pluralize, ROUTE_PATTERNS } from '@util'

import { Toast } from '../Toast'

import {
  ApiSearchResult,
  calcMaxScrollerHeight,
  hideUnpairedQuotes,
  PAGE_SIZE,
} from './helpers'

import styles from './Results.module.scss'

type SearchResult = ZInfer<typeof schemas.SearchResultItem>
type ClassicSearchResult = ZInfer<typeof schemas.ClassicSearchResult>
type ScriptSearchResult = ZInfer<typeof schemas.ScriptSearchResult>

function isClassicResult(result: SearchResult): result is ClassicSearchResult {
  return (result as ClassicSearchResult).documentType === 'classic'
}

function isScriptResult(result: SearchResult): result is ScriptSearchResult {
  return (result as ScriptSearchResult).documentType === 'script'
}

const AccessLevelBadge = ({ item }: { item: SearchResult }) => {
  const { resolveAccessLevel } = useMst()

  if (item.documentType !== 'script') {
    return null
  }
  const accessLevel = resolveAccessLevel({
    folderId: item.folderId,
    status: item.status,
  })

  if (accessLevel === 'OPEN') {
    return null
  }

  return (
    <Tooltip label={capitalize(accessLevel)}>
      <ScriptStatusIcon status={accessLevel} size="xs" />
    </Tooltip>
  )
}

export const LastUpdated = ({
  updatedAt,
  lastEditorName,
}: {
  updatedAt: string
  lastEditorName?: string
}) => {
  const time = new Date(updatedAt)
  return (
    <time dateTime={time.toISOString()}>
      {getDateFormatting({
        now: new Date(),
        lastUpdated: time,
        user: lastEditorName,
      }).replace('Last edited by ', '')}
    </time>
  )
}

const DocTypeLookup: Record<
  SearchResult['documentType'],
  {
    route: string
    icon: FaIconName
  }
> = {
  script: { route: 'scripts', icon: 'fa-script' },
  rundown: { route: 'rundowns', icon: 'fa-table-cells' },
  classic: { route: 'wayback', icon: 'fa-box-archive' },
}

const SearchResultIcon = ({ item }: { item: SearchResult }) => {
  if (isClassicResult(item)) {
    return <ArchivedScriptIcon goldtooth={item.inRundown} />
  }

  const icon = isScriptResult(item)
    ? item.icon
    : DocTypeLookup[item.documentType].icon

  return <FaIcon icon={icon} c="dark" />
}

const Breadcrumb = ({ name, inTrash }: { name: string; inTrash: boolean }) => (
  <Group className={styles.search_crumb} gap={8} wrap="nowrap">
    <FaIcon icon={inTrash ? 'fa-trash' : 'fa-folder'} />
    <Text size="15" fw="bold">
      {inTrash ? 'Trash' : name}
    </Text>
  </Group>
)

const Result = observer(function (item: SearchResult) {
  const { documentType, id, name, highlight, contentsModifiedAt } = item

  const { currentOrg, folderMap, currentRundown, currentScript } = useMst()
  const [, waybackParams] = useRoute(ROUTE_PATTERNS.archivedScript)
  const { pathToRundown, pathToScript } = useNavigation()

  const textContent = highlight.textContent?.join(' ')
  const folder =
    documentType === 'classic' ? null : folderMap.get(item.folderId)
  const displayBreadcrumb =
    !!folder && (!folder.isRootFolder || folder.belongsToTrashTree)

  const lastEditorName =
    documentType === 'classic'
      ? undefined
      : currentOrg?.getMember(item.contentsModifiedBy)?.name

  const href: NavAnchorHref =
    documentType === 'script'
      ? pathToScript(id)
      : documentType === 'rundown'
        ? pathToRundown(String(id))
        : `/wayback/${id}`

  const isSelected =
    [currentRundown?.id, currentScript?.id].includes(id) ||
    waybackParams?.id === id

  return (
    <NavAnchor href={href} className={styles.search_nav}>
      <Stack
        className={cn(styles.search_card, {
          [styles.search_card___selected]: isSelected,
        })}
      >
        <Stack gap={5}>
          <Group gap="xs" wrap="nowrap">
            <Stack gap={0}>
              <Group fw="bold" gap={8} wrap="nowrap">
                <SearchResultIcon item={item} />
                <Text size="15" c="dark" fw="bold">
                  {name}
                </Text>
                <AccessLevelBadge item={item} />
              </Group>
            </Stack>
          </Group>
          {displayBreadcrumb && (
            <Breadcrumb
              name={folder.name}
              inTrash={folder.belongsToTrashTree}
            />
          )}
          {isClassicResult(item) && item.folderName && (
            <Text size="15" fw="bold" c="gray.6">
              from &quot;{item.folderName}&quot;
            </Text>
          )}
          {textContent && (
            <Text c="dark" size="15">
              <span
                className={styles.search_snippet}
                dangerouslySetInnerHTML={{ __html: textContent }}
              />
            </Text>
          )}
          <Text c="dimmed" size="15">
            <LastUpdated
              lastEditorName={lastEditorName}
              updatedAt={contentsModifiedAt.toISOString()}
            />
          </Text>
        </Stack>
      </Stack>
    </NavAnchor>
  )
})

export const SearchResults = ({
  apiResult,
  filterOffset,
}: {
  apiResult: ApiSearchResult
  filterOffset?: number
}) => {
  if (!apiResult.isSuccess || apiResult.data.body.total === 0) {
    return null
  }

  const {
    body: { total, results },
  } = apiResult.data
  const mustPaginate = total > PAGE_SIZE
  const maxHeight = calcMaxScrollerHeight({
    isPaginated: mustPaginate,
    filterOffset,
  })

  return (
    <>
      <Divider />
      <Stack className={styles.search_results} style={{ maxHeight }}>
        <Stack gap={0}>
          {results.map((d) => (
            <Result key={d.id} {...d} />
          ))}
        </Stack>
      </Stack>
    </>
  )
}

export const ResultsSummary = ({
  apiResult,
  searchTerm,
}: {
  apiResult: ApiSearchResult
  searchTerm: string
}) => {
  const { isError, isLoading, data } = apiResult
  if (isLoading) {
    return null
  }
  if (isError) {
    return (
      <Toast
        type="error"
        icon="fa fa-exclamation-triangle"
        dismissable={false}
        message="Sorry, there was a problem. Please try your search again."
      />
    )
  }

  const { total, from, results } = data.body

  if (results.length === 0) {
    return (
      <Toast
        type="neutral"
        icon="fa fa-face-frown"
        dismissable={false}
        message="No results found. Try a different search term."
      />
    )
  } else {
    const richCount = `${from + 1}-${
      from + results.length
    } of ${total} ${pluralize(total, 'result')} ${searchTerm ? ' for ' : ''}`

    return (
      <Text size="sm" lineClamp={1}>
        {richCount}
        <Text span fw="bold">
          {hideUnpairedQuotes(searchTerm)}
        </Text>
      </Text>
    )
  }
}
