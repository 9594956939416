import React from 'react'

import { Divider, Stack, Table, Text } from '@mantine/core'

import { SmartFaIcon } from '@components/FaIcon'
import { StaticSnapshotViewer } from '@components/SnapshotViews/SnapshotViewer'
import { useStaffFormats } from '@hooks'
import { ScriptFormatConfiguration } from '@util/formats'
import { ScriptSnapshotPayload } from '@util/ScriptoApiClient/types'

import { CreateFormatForm } from './CreateFormatForm'
import { LoadSnaphotForm } from './LoadSnapshotForm'
import './FormatEditor.scss'

const FormatsList = () => {
  const { formatsQuery } = useStaffFormats()
  const { isLoading, isError, data } = formatsQuery

  if (isLoading) {
    return <div>loading formats</div>
  }
  if (isError) {
    return <div>error fetching formats</div>
  }

  return (
    <Table>
      <Table.Thead>
        <Table.Th>Name</Table.Th>
        <Table.Th>id</Table.Th>
        <Table.Th>icon</Table.Th>
        <Table.Th>replaced by</Table.Th>
      </Table.Thead>
      <Table.Tbody>
        {data.map(({ name, id, replacedById, icon }) => (
          <Table.Tr key={id}>
            <Table.Td>{name}</Table.Td>
            <Table.Td>{id}</Table.Td>
            <Table.Td>
              <SmartFaIcon icon={icon} fallback={<div>icon</div>} />
            </Table.Td>
            <Table.Td>{replacedById}</Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}

export const FormatEditor = () => {
  const [snapshot, setSnapshot] = React.useState<ScriptSnapshotPayload | null>(
    null,
  )

  const applyModifiedDefinition = (definition: ScriptFormatConfiguration) => {
    if (snapshot) {
      const newSnapshot = {
        ...snapshot,
        scriptFormat: {
          ...snapshot.scriptFormat,
          definition,
          id: new Date().toISOString(),
        },
      }
      setSnapshot(newSnapshot)
    }
  }
  if (!snapshot) {
    return (
      <Stack className="c-format-editor-wrapper">
        <LoadSnaphotForm onSnapshotLoaded={setSnapshot} />
        <Divider />
        <Text fw="bold">Available Formats</Text>
        <FormatsList />
      </Stack>
    )
  }

  return (
    <div className="c-format-editor">
      <div className="c-format-editor__tools">
        <CreateFormatForm
          onApplyFormat={applyModifiedDefinition}
          currentFormat={snapshot.scriptFormat}
        />
      </div>
      <div className="snapshot-wrapper">
        <StaticSnapshotViewer snapshot={snapshot} />
      </div>
    </div>
  )
}
