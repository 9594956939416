import { email } from '@sideway/address'
import { format } from 'date-fns'

import { IFolder } from '@state/types'

export { v4 as uuid } from 'uuid'

export * from './ScriptoApiClient'
export * from './mixpanel'
export * from './getAvatarUrl'
export * from './prosemirrorHelpers'
export * from './scripts'
export * as styleHelpers from './styleHelpers'
export * from './fileDownload'
export * from './safeParseJSON'
export * as formUtil from './formUtil'
export * from './parseServerError'
export * from './pathConfigs'
export * from './pathUtil'
export * from './buildScrapiClient'
export * from './fireAndForget'

export const noop = () => {
  // do nothing
}

export const noopAsync = () => Promise.resolve()

export const delay = (seconds: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000)
  })

// call to reload window with a dummy delay at the end. This lets us
// prevent some unwanted UI from rendering after we trigger a reload
export const reloadWindow = async (path?: string) => {
  if (path) {
    window.history.pushState({}, '', path)
  }
  window.location.reload()
  await delay(2)
}

export const stringMapEqual = (a: StringMap, b: StringMap): boolean => {
  if (a === b) {
    return true
  }
  const keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) {
    return false
  }
  return !keys.find((key) => a[key] !== b[key])
}

export const capitalize = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()

export const omitSharedRootFolder = (folders: IFolder[]): IFolder[] => {
  // the shared root is omitted from breadcrumbs
  if (
    folders.length > 0 &&
    folders[0].isRootFolder &&
    !folders[0].isPrivate &&
    !folders[0].belongsToTrashTree
  ) {
    folders.splice(0, 1).pop()
  }
  return folders
}

export const isEmailValid = (value: unknown): boolean => {
  if (typeof value === 'string') {
    return email.isValid(value)
  }
  return false
}

export const pluralize = (count: number, text: string) =>
  count === 1 ? text : text + 's'

export const getPrintTimestamp = () => {
  return format(new Date(), "M/d/yyyy 'at' h:mm aa")
}

export function isInteger(val: unknown): val is number {
  return Number.isInteger(val)
}

// handy little generic typeguard... if you have an array of thigs that are a mix of
// T and null/undefined you can use this filter to typecast it to an array of T
// see https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
export function notEmptyFilter<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined
}

// create a sort-by-date function based on an arbitrary field name
export const buildDateSortFn = <Field extends string>(
  field: Field,
  direction: 'asc' | 'desc',
) => {
  return (a: Record<Field, Date>, b: Record<Field, Date>) => {
    const diff = a[field].valueOf() - b[field].valueOf()
    return direction === 'asc' ? diff : -1 * diff
  }
}

// for turning stuff like "&apos;" into "'"
// (because for some reason we encode comment text)
export const decodeHtmlString = (input?: string | null): string => {
  if (!input) {
    return ''
  }
  const doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent ?? ''
}
