import { EditorState } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { z } from 'zod'

import { NodeTypeMap } from '@showrunner/codex'
import { schemas } from '@showrunner/scrapi'

import { getSelectionBlock } from '@util'
import { FormatBlockName } from '@util/formats'

export const setSpacingAttr = ({
  key,
  value,
  editorView,
}: {
  key: 'lineHeight' | 'blockTopMargin'
  value: number | null
  editorView: EditorView
}) => {
  const { doc, selection, tr } = editorView.state
  // update alignment for all nodes in selection that have a line spacing attr
  doc.nodesBetween(selection.from, selection.to, (node, position) => {
    const shortCircuit =
      node.isText || node.type.name === NodeTypeMap.PAGE || !(key in node.attrs)

    if (shortCircuit) return

    const newAttrs = { ...node.attrs, [key]: value }
    tr.setNodeMarkup(position, undefined, newAttrs, node.marks)
  })
  editorView.dispatch(tr)
  editorView.focus()
}

// if this is right, port to scrapi
// https://stackoverflow.com/questions/73570071/merge-of-two-enums-zod
// https://github.com/showrunner/scrapi/blob/db0324cbbcf72fb42c33c6472f4a6cfb3e0fc297/src/schemas/scriptFormats/blocks.ts#L23
const AllBlocks = z.enum([
  ...schemas.scriptFormats.StandardBlocks.options,
  ...schemas.scriptFormats.DualFormatBlocks.options,
] as const)

const AllFormatNames: readonly string[] = [...AllBlocks.options]

function isFormatBlockName(
  value: string | undefined,
): value is FormatBlockName {
  return AllFormatNames.includes(value ?? '')
}

export const getSelFormatBlockName = (
  state: EditorState,
): FormatBlockName | undefined => {
  const name = getSelectionBlock(state)?.type.name
  return isFormatBlockName(name) ? name : undefined
}

export const VALID_LINE_HEIGHTS = [1, 1.5, 2]
