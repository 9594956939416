import { observer } from 'mobx-react-lite'

import { SnapshotViewerBase } from '@components/SnapshotViews/SnapshotViewer'
import { useMst } from '@hooks'
import { createSnapshotEditorState } from '@util'
import { ScriptSnapshotPayload } from '@util/ScriptoApiClient/types'

import { BodyPlaceholder } from './BodyPlaceholder'
import { useSnapshotData } from './useSnapshotLandData'
import { useSnapshotQueryParams } from './useSnapshotQueryParams'

const PopulatedStaticView = ({
  snapshot,
}: {
  snapshot: Pick<ScriptSnapshotPayload, 'doc' | 'scriptFormat'>
}) => {
  const mst = useMst()
  const { pageless, editorZoom } = mst.user.prefs
  const editorState = createSnapshotEditorState(snapshot, mst)

  return (
    <SnapshotViewerBase
      pageless={pageless}
      editorZoom={editorZoom}
      editorState={editorState}
      format={snapshot.scriptFormat}
    />
  )
}

// Once user has supplied an ID
const RequestedStaticView = observer(function StaticView({
  scriptId,
  snapshotId,
}: {
  scriptId: string
  snapshotId: string
}) {
  const query = useSnapshotData({ scriptId, snapshotId })
  if (query.isLoading) {
    return <BodyPlaceholder issueType="loading" />
  }
  if (query.isError) {
    return <BodyPlaceholder issueType="error" />
  }

  return <PopulatedStaticView snapshot={query.data} />
})

export const StaticView = observer(function StaticView() {
  const { snap1, scriptId } = useSnapshotQueryParams()
  if (!(snap1 && scriptId)) {
    return <BodyPlaceholder />
  }

  return <RequestedStaticView scriptId={scriptId} snapshotId={snap1} />
})
