import { ActionIcon, Card, Group, Stack, Text } from '@mantine/core'
import cn from 'classnames'

import { FaIcon } from '@components/FaIcon'
import { SnapshotSummary } from '@util/ScriptoApiClient/types'

import * as helpers from './helpers'

import styles from './SnapshotCard.module.scss'

export type SelectionMode = 'solo' | 'comparison'

type SnapshotCardProps = {
  data?: SnapshotSummary
  sticky?: boolean
  selected?: boolean
  onClick?: () => void
  onClose?: () => void
}

export const SnapshotCard = ({
  data,
  onClick,
  onClose,
  selected,
  sticky,
}: SnapshotCardProps) => {
  const classes = cn(styles.snapshotCard, {
    [styles.clickable]: !selected,
    [styles.circled]: selected && sticky,
    [styles.active]: selected,
  })

  const manual = !!data?.name
  const title = data ? helpers.title(data) : 'Loading...'
  const subtitle = data ? helpers.subtitle(data) : 'Loading...'

  return (
    <Card padding="sm" className={classes} onClick={onClick}>
      <Group gap={0} justify="space-between" wrap="nowrap">
        <Stack gap={2}>
          <Text lineClamp={1} fw={manual ? 'bold' : undefined}>
            {title}
          </Text>
          <Text lineClamp={2} c="dimmed">
            {subtitle}
          </Text>
        </Stack>
        {!!onClose && (
          <ActionIcon onClick={onClose}>
            <FaIcon c="dark.9" icon="fa-xmark" size="14" />
          </ActionIcon>
        )}
      </Group>
    </Card>
  )
}

export const ChooseSnapshotCard = ({ compare }: { compare?: boolean }) => (
  <Card padding="sm" className={styles.chooseSnapshotCard}>
    <Text lineClamp={1}>
      {compare ? 'Choose a snapshot to compare' : 'Choose a snapshot'}
    </Text>
  </Card>
)
