import { format } from 'date-fns'
import { types } from 'mobx-state-tree'

import {
  NodeTypeKey,
  ScriptFormat,
  ScriptFormatMap,
  ScriptStatus,
  ScriptStatusMap,
} from '@showrunner/codex'
import { schemas, ZInfer } from '@showrunner/scrapi'

import { EditorManager } from '@choo-app/lib/editor/EditorManager'
import { docInfoPluginKey } from '@choo-app/lib/editor/plugins/doc-info'
import type { CommentPojo } from '@components/Comments/types'
import { showMoveToFolder } from '@components/Modals'
import { launchScriptToast } from '@components/Toast'
import {
  IComment,
  ICommentThread,
  ILoadedScript,
  IPopulatedPmEditor,
  NumberableNodeTypeKey,
} from '@state/types'
import { BLACKISH_TEXT } from '@theme/colors'
import { notEmptyFilter, saveTextToFile } from '@util'
import {
  NavLinkData,
  PDF_HEADER_DATEMASK,
  SCREENPLAY_NUMBERABLE_KEYS,
  SHARE_SCRIPT_WARNING_MESSAGE,
  STUDIO_NUMBERABLE_KEYS,
} from '@util/constants'
import {
  BlockFormats,
  BlockOverrides,
  FormatBlockName,
  FormatOption,
  FormatOptionValue,
} from '@util/formats'
import {
  BETA_EXPORTED_FDX,
  SCRIPT_EXPORTED_CHARACTER_REPORT,
  SCRIPT_EXPORTED_FOUNTAIN,
  SCRIPT_EXPORTED_RUNDOWN,
  SCRIPT_EXPORTED_TXT,
  SCRIPT_NAV_REORDERED,
} from '@util/mixpanel/eventNames'
import * as pmHelpers from '@util/prosemirrorHelpers'
import { RawRemoteUser, ScriptPayload } from '@util/ScriptoApiClient/types'

import { ElementNumberSummary, isIdentifiableSummary } from '../types'

import { CommentData } from './CommentData'
import { IsoDate } from './IsoDate'
import { ScriptListingBase } from './ListingBase'
import { PmDomInfo } from './PmDomInfo'
import { PmEditor } from './PmEditor'
import { PrompterPushCandidate } from './PrompterPushCandidate'
import { RemoteCollaborator } from './RemoteCollaborator'
import { ScriptFormatModel } from './ScriptFormats'
import { ScriptEventPayload } from './SocketManager/helpers'
import { SyncStatus } from './SyncStatus'

// A LoadedScript is one in the editor. This is where we keep information
// about things like the script nav, sync status, etc.
export const LoadedScript = ScriptListingBase.named('LoadedScript')
  .props({
    // these values we know before GET script/:scriptId
    id: types.string,
    type: types.enumeration<ScriptFormat>(Object.values(ScriptFormatMap)),
    scriptFormat: ScriptFormatModel,

    // this is what we got back on GET script -- the choo app doesn't update
    // the value in state... it's really the availableServerVersion but then it gets
    // out of date
    version: types.number,
    orgId: types.string,
    readRate: types.number,
    createdAt: IsoDate,
    slackWebhook: types.boolean,
    // these values get loaded after & updated by the choo app
    syncStatus: types.optional(SyncStatus, {}),
    prompterPushCandidate: types.maybe(PrompterPushCandidate),
    showPrompterView: false,
    elementMenuOpened: false,
    pmEditor: types.optional(PmEditor, {}),
    pmDomInfo: types.optional(PmDomInfo, {}),
    cutComments: types.array(types.string),
    collaborators: types.array(RemoteCollaborator),
    doc: types.frozen<ScriptPayload['doc']>(),
    commentData: types.optional(CommentData, {}),
  })
  .views((self) => ({
    get isInk(): boolean {
      return self.scriptFormat.definition.scriptType === 'ink'
    },
    get paginationType(): pmHelpers.PaginationType | undefined {
      return self.pmEditor.configData?.paginationType
    },
  }))
  .views((self) => ({
    // The pmEditor instance either has both editorView and editorState
    // present or neither, but the volatile state makes that hard to
    // tease out. This helper gives you all or nothing
    get observableEditor(): IPopulatedPmEditor | undefined {
      if (self.pmEditor.editorState && self.pmEditor.editorView) {
        return self.pmEditor as IPopulatedPmEditor
      }
    },
    get path() {
      return `/scripts/${self.id}`
    },
    get sortedNavLinks() {
      return self.pmEditor.navLinks
    },
    get hasRemoteUsers(): boolean {
      return self.collaborators.length > 0
    },
    get isScreenplay(): boolean {
      return self.type === ScriptFormatMap.SCREENPLAY
    },
    get isOpen(): boolean {
      return self.accessLevel === ScriptStatusMap.OPEN
    },
    get isLimited(): boolean {
      return self.accessLevel === ScriptStatusMap.LIMITED
    },
    get isEditable(): boolean {
      return (
        !self.inTrash && (!this.isLimited || self.rootStore.user.canEditLimited)
      )
    },
    get isRestorable(): boolean {
      return !this.isLimited || self.rootStore.user.canEditLimited
    },
    get isDestroyable(): boolean {
      return (
        self.accessLevel === ScriptStatusMap.PRIVATE ||
        (self.rootStore.user.canDestroyContent && this.isRestorable)
      )
    },
    get canReorderSections(): boolean {
      return this.isEditable
    },
    get numberableBlockTypes() {
      const possibleTypes: NumberableNodeTypeKey[] = this.isScreenplay
        ? SCREENPLAY_NUMBERABLE_KEYS
        : STUDIO_NUMBERABLE_KEYS
      return possibleTypes
    },
    // find the nav link that includes the current cursor position or selection start
    get currentNavLink(): NavLinkData | undefined {
      const position = this.observableEditor?.selection?.$from.pos
      if (typeof position === 'number') {
        return self.pmEditor.navLinks.find(
          (nl) => nl.pos <= position && position - nl.pos < nl.nodeSize,
        )
      }
    },
    get isLinkedToRundown(): boolean {
      return !!self.rootStore.currentRundown?.orderedScripts.some(
        (s) => s.scriptId === self.id,
      )
    },
    get blocksWithElementNumbers(): ElementNumberSummary[] {
      const { editorState } = self.pmEditor
      if (!editorState) return []

      const docInfo = docInfoPluginKey.getState(editorState)

      return Object.values(docInfo?.elementNumbers ?? {})
        .filter((a) => a.nodeExists && a.isNumbered)
        .flatMap((b) => b.blocks)
        .filter(isIdentifiableSummary)
    },

    threadIsOrphaned(threadId: string) {
      const { commentInventory } = self.pmEditor
      return !commentInventory?.commentMarkInfo[threadId]
    },

    commentInOrphanedThread(comment: IComment): boolean {
      return this.threadIsOrphaned(comment.threadId)
    },

    get totalCommentCount() {
      let total = 0
      for (const [
        threadId,
        commentCount,
      ] of self.commentData.threadCountMap.entries()) {
        if (!this.threadIsOrphaned(threadId)) {
          total += commentCount
        }
      }
      return total
    },

    get openComments(): CommentPojo[] {
      return (
        self.commentData.allComments
          // filter out deleted
          .filter((c) => !c.deletedAt)
          // filter out orphans
          .filter((c) => !this.commentInOrphanedThread(c))
          // filter out resolved
          .filter((c) => !self.commentData.inResolvedThread(c.threadId))
      )
    },

    get resolvedAndOrphanThreads(): Array<
      ICommentThread & { orphaned: boolean }
    > {
      return self.commentData.threads
        .map((thread) => ({
          ...thread,
          orphaned: this.commentInOrphanedThread(thread.rootComment),
        }))
        .filter((thread) => {
          return thread.resolvedAt || thread.orphaned
        })
    },

    get openCommentThreads(): Array<
      ICommentThread & {
        pos: number
      }
    > {
      const { commentInventory } = self.pmEditor
      if (!commentInventory) {
        return []
      }

      const { commentMarkInfo, unsavedComment } = commentInventory

      const openThreads = self.commentData.threads
        .filter((t) => !t.resolvedAt)
        .map((t) => {
          const pos = commentMarkInfo[t.id]?.pos
          if (pos) return { ...t, pos }
        })
        .filter(notEmptyFilter)

      const { threadBeingAdded } = self.commentData
      if (threadBeingAdded && unsavedComment) {
        openThreads.push({
          ...threadBeingAdded,
          pos: unsavedComment.pos,
        })
      }

      return openThreads
    },
    get pageCount() {
      const { editorState } = self.pmEditor
      if (editorState) {
        return pmHelpers.getPageCount(editorState)
      }
    },
    get allowFormatOverrides(): boolean {
      return (
        self.paginationType === 'inline' &&
        self.rootStore.view.hasBetaFormatting
      )
    },
    get originalBlockFormats(): BlockFormats {
      return self.scriptFormat.definition.blocks
    },
    get blockOverrides(): BlockOverrides | undefined {
      return self.pmEditor.configData?.blockOverrides
    },
    get blockFormats(): BlockFormats | undefined {
      return self.pmEditor.configData?.currentBlockFormats
    },
    // script formats can include info about block types that are suppressed in the UI
    // IE: this returns bracket pink for a default screenplay
    get originalFormatColors(): string[] {
      const customColors = Object.values(this.originalBlockFormats)
        .map((f) => f.color)
        .filter(notEmptyFilter)

      return [BLACKISH_TEXT, ...new Set(customColors)]
    },
    // well-typed lookup helper for current value of a block format
    // e.g. currentFormatValue('bracket', 'marginLeft')
    currentFormatValue<T extends FormatOption>(
      block: FormatBlockName,
      option: T,
    ): FormatOptionValue<T> {
      return (
        this.blockFormats?.[block][option] ??
        this.originalBlockFormats[block][option]
      )
    },
    originalFormatValue<T extends FormatOption>(
      block: FormatBlockName,
      option: T,
    ): FormatOptionValue<T> {
      return this.originalBlockFormats[block][option]
    },
  }))
  .actions((self) => ({
    setShowPrompterView(value: boolean) {
      const eventName = value
        ? self.MIXPANEL_EVENTS.PROMPTER_VIEW_OPENED
        : self.MIXPANEL_EVENTS.PROMPTER_VIEW_CLOSED

      self.trackEvent(eventName)
      self.showPrompterView = value
    },
    setElementMenuOpened(value: boolean) {
      self.elementMenuOpened = value
    },
    createPushCandidate() {
      const candidate = PrompterPushCandidate.create({
        parentId: self.id,
        parentType: 'script',
      })
      self.prompterPushCandidate = candidate
      return candidate
    },
    clearPushCandidate() {
      self.prompterPushCandidate = undefined
    },
    setSlackWebhook(value: boolean) {
      self.slackWebhook = value
    },
    setName(name: string) {
      self.name = name
    },
    setFolderId(value: string) {
      self.folderId = value
    },
    toggleElementNumbers(nodeType: NodeTypeKey) {
      const { editorView } = self.pmEditor
      if (editorView) {
        pmHelpers.toggleElementNumbers({
          editorView,
          nodeType,
        })
      }
    },
    share() {
      const listing = self.rootStore.scriptMap.get(self.id)
      const rootFolder = self.rootStore.rootFolders.sharedDashboard
      if (rootFolder && listing) {
        const folderListState =
          self.rootStore.view.initializeReadonlyFolderState(rootFolder)
        const name = ` "${listing.name}"`
        showMoveToFolder({
          title: 'Share Script',
          itemName: name,
          failureMessage: 'Could not share' + name,
          warningMessage: SHARE_SCRIPT_WARNING_MESSAGE,
          onSubmit: (folderId) => listing.moveToFolder(folderId),
          folderListState: folderListState,
        })
      }
    },
    updateEditorViewObservables() {
      self.pmEditor.syncEditorView()
    },
    // We call this to toggle the editability based on error states, the user
    // may have the rights to edit, but we're disconnected or sync is failing
    setPmEditability(allowEdits: boolean) {
      const { isEditable } = self
      const { editorManager } = self.pmEditor

      const script = self as ILoadedScript
      if (editorManager) {
        if (allowEdits && isEditable) {
          editorManager.setEditable(true, script)
        }
        if (!allowEdits) {
          editorManager.setEditable(false, script)
          self.pmEditor.setEditabilityLost(true)
        }
      }
    },
    setCutComments(cutComments: string[]) {
      self.cutComments.replace(cutComments)
    },
    clearCutComments() {
      self.cutComments.replace([])
    },
    goToCommentMark(commentId: string) {
      const domNode = self.commentData.commentMap
        .get(commentId)
        ?.findMarkElement()
      if (domNode && self.observableEditor) {
        pmHelpers.goToHtmlNode({
          domNode,
          editorView: self.observableEditor.editorView,
        })
      }
    },
    setCollaborators(data: RawRemoteUser[]) {
      // don't add ourselves
      const ownId = self.rootStore.socketManager.socketId
      const remoteUserData = data.filter((d) => d.clientId !== ownId)
      self.collaborators.replace(
        remoteUserData.map((item) => RemoteCollaborator.create(item)),
      )
    },

    addCollaborator(
      payload: ZInfer<typeof schemas.sockets.USER_JOINED>,
      editorManager: EditorManager,
    ) {
      const userData = payload.users.find(
        (u) => u.clientId === payload.clientId,
      )
      const isSelf = payload.clientId === self.rootStore.socketManager.socketId

      const isNew = !self.collaborators.some(
        (c) => c.clientId === payload.clientId,
      )

      if (userData && !isSelf && isNew) {
        self.collaborators.push(userData)
        editorManager.onUserJoin()
      }
    },

    removeCollaborator(
      payload: ZInfer<typeof schemas.sockets.USER_LEFT>,
      editorManager: EditorManager,
    ) {
      const userToRemove = self.collaborators.find(
        (c) => c.clientId === payload.clientId,
      )
      if (userToRemove) {
        self.collaborators.remove(userToRemove)
      }
      editorManager.onUserLeft()
    },

    updateCollaboratorPosition(
      data: ZInfer<typeof schemas.sockets.CURSOR_UPDATED>,
      editorManager: EditorManager,
    ) {
      const currentVersion =
        self.observableEditor?.editorManager?.locallyConfirmedVersion
      const collaborator = self.collaborators.find(
        (c) => c.clientId === data.clientId,
      )
      if (collaborator && currentVersion === data.version) {
        collaborator.update(data)
        editorManager.onCursorUpdated()
      }
    },
    handleSocketMessage(payload: ScriptEventPayload) {
      const editorManager = self.observableEditor?.editorManager
      if (editorManager) {
        switch (payload.eventType) {
          case 'SCRIPT_STATUS_CHANGED':
            self.setSharedStatus(payload.statusType)
            break
          case 'USER_JOINED':
            this.addCollaborator(payload, editorManager)
            break
          case 'USER_LEFT':
            this.removeCollaborator(payload, editorManager)
            break
          case 'CURSOR_UPDATED':
            this.updateCollaboratorPosition(payload, editorManager)
            break
          case 'COMMENT_ADDED':
          case 'COMMENT_DELETED':
          case 'COMMENT_RESOLVED':
          case 'COMMENT_UNRESOLVED':
          case 'COMMENT_UPDATED':
            self.commentData.processSocketMessage(payload)
            break
          case 'SCRIPT_UPDATED':
            editorManager.onScriptVersionUpdated(payload, 'socket')
            break
        }
      }
    },
    handleJoinedRoom({
      users,
      version,
    }: {
      users: ZInfer<typeof schemas.sockets.remoteUser>[]
      version: number
    }): void {
      const editorManager = self.observableEditor?.editorManager
      if (editorManager && !editorManager.isDestroyed) {
        this.setCollaborators(users)
        editorManager.onJoinedRoom({
          script: self as ILoadedScript,
          version,
          editable: self.isEditable,
        })
      }
      // any time we rejoin, we might have missed comment messages
      self.commentData.refreshCounts(self.id)
      self.commentData.loadHistory(self.id)
    },
    handleLostConnection() {
      this.setCollaborators([])
      const editorManager = self.observableEditor?.editorManager
      if (editorManager) {
        editorManager.onDisconnected(self as ILoadedScript)
      }
    },

    async launchEditor(element: HTMLElement) {
      this.tearDownEditor()

      const editorManager = new EditorManager({
        scriptId: self.id,
        mst: self.rootStore,
      })

      editorManager.start(self as ILoadedScript, element)
      self.pmEditor.setEditorManager(editorManager)

      const { socketManager } = self.rootStore
      try {
        await socketManager.connectAndJoinScript(self.id)
      } catch {
        launchScriptToast({
          type: 'error',
          message:
            'Timed out while awaiting server connection. Please make sure you are connected to the internet.',
        })
      }
    },
    tearDownEditor() {
      const editorManager = self.observableEditor?.editorManager
      if (editorManager) {
        self.rootStore.socketManager.leaveScript(self.id)
        editorManager.destroy()
        self.pmEditor.setEditorManager(null)
      }
    },
    handleSocketAccessError(message: string) {
      launchScriptToast({
        type: 'error',
        message,
      })
    },
    // When first loaded, we get the prose json doc and version from the server.
    // after that, the prosemirror editor state has a more current version. In cases
    // where we need to recreate the prosemirror editor state without a reload, call
    // this first to update our own values.
    //
    // In a future world, we can stop keeping the stale data and keep the editor
    // state in mst and allow prosemirror to update it.
    updateSelfFromEditor() {
      const { editorManager } = self.pmEditor
      if (
        editorManager &&
        editorManager.view &&
        // we can't do this trick if the editorState.doc and the version are
        // not matching, so this is just a best effort
        !editorManager.hasLocalStepsToSend
      ) {
        self.doc = editorManager.view.state.doc.toJSON() as ScriptPayload['doc']
        self.version = editorManager.locallyConfirmedVersion
      }
    },
  }))
  .actions((self) => ({
    async updateName(name: string) {
      await self.apiClient.renameScript({
        scriptId: self.id,
        name,
      })
      self.setName(name)
    },
    async createSnapshot(name = 'Snapshot') {
      await self.apiClient.createSnapshot({ scriptId: self.id, name })
    },
    async updateStatus(status: ScriptStatus) {
      const response = await self.apiClient.updateScriptStatus({
        scriptId: self.id,
        status,
        socketId: self.rootStore.socketManager.socketId,
      })
      self.setSharedStatus(response.status)
    },
    async moveNavLink(oldIndex: number, newIndex: number) {
      const { editorView } = self.pmEditor
      if (!editorView) return
      pmHelpers.reorderScenes({
        oldIndex,
        newIndex,
        navLinks: self.pmEditor.navLinks,
        editorView,
      })
      self.trackEvent(SCRIPT_NAV_REORDERED, { scriptId: self.id })
    },
    updateElementNumber({ id, value }: { id: string | null; value: string }) {
      const { editorView } = self.pmEditor
      if (!editorView || !id) return

      editorView.state.doc.descendants((node, pos) => {
        const { attrs } = node
        if (attrs.id === id) {
          const elementNumber = value === '' ? null : value
          // only dispatch a transaction if a new value was provided
          if (elementNumber !== attrs.elementNumber) {
            const newAttrs = { ...attrs, elementNumber }
            const tr = editorView.state.tr.setNodeMarkup(
              pos,
              undefined,
              newAttrs,
            )
            editorView.dispatch(tr)
          }
        }
      })
    },
    moveNavLinkAboveOtherLink({
      movedLinkId,
      targetLinkId,
    }: {
      movedLinkId: string
      targetLinkId: string
    }) {
      const movedLink = self.sortedNavLinks.find((l) => l.id === movedLinkId)
      const targetLink = self.sortedNavLinks.find((l) => l.id === targetLinkId)

      // make sure we've got valid references. The UI should prevent this from
      // happening, but hey... belt and suspenders
      if (!(movedLink && targetLink)) {
        return
      }

      const movedLinkIndex = self.sortedNavLinks.indexOf(movedLink)
      const targetLinkIndex = self.sortedNavLinks.indexOf(targetLink)

      // if we're moving a nav link to be above a new act and there was a
      // an end of act above that, put the nav link in the previous act
      const moveAboveEndOfAct =
        targetLink.type === 'new_act' &&
        self.sortedNavLinks[targetLinkIndex - 1]?.type === 'end_of_act'
      const adjustedIndex = moveAboveEndOfAct
        ? targetLinkIndex - 1
        : targetLinkIndex

      // more belt and suspenders... UI should prevent the first two of these
      if (
        movedLinkIndex === adjustedIndex || // don't move it above itself (nonsensical)
        movedLinkIndex === adjustedIndex - 1 || // don't move it above its current successor (same place)
        movedLinkIndex === -1 || // make sure the items are still in the list (race condition?)
        adjustedIndex === -1
      ) {
        return
      }

      this.moveNavLink(movedLinkIndex, adjustedIndex)
    },

    // slightly different logic for moving link to the end
    async moveNavLinkToEnd(movedLinkId: string) {
      const movedLink = self.sortedNavLinks.find((l) => l.id === movedLinkId)
      const finalLink = self.sortedNavLinks[self.sortedNavLinks.length - 1]
      if (!(movedLink && finalLink)) {
        return
      }

      // if the final link is end_of_act, we want to move ABOVE it, so
      // hand this off to moveNavLinkAboveOtherLink
      if (finalLink.type === 'end_of_act') {
        this.moveNavLinkAboveOtherLink({
          movedLinkId: movedLink.id,
          targetLinkId: finalLink.id,
        })
        return
      }

      const movedLinkIndex = self.sortedNavLinks.indexOf(movedLink)
      const targetIndex = self.sortedNavLinks.length
      // verify we're moving to a valid place that isn't its current position
      // (probably this is all redundant)
      if (
        movedLinkIndex === -1 ||
        movedLinkIndex === targetIndex ||
        movedLinkIndex === targetIndex - 1
      ) {
        return
      }

      this.moveNavLink(movedLinkIndex, targetIndex)
    },
    async fetchSnapshotHistory({
      from = 0,
      size = 20,
      filter = 'all',
    }: {
      from?: number
      size?: number
      filter?: 'manual' | 'all'
    }) {
      return await self.apiClient.fetchSnapshotHistory({
        scriptId: self.id,
        from,
        size,
        filter,
      })
    },
    async updateSnapshot({
      snapshotId,
      name,
    }: {
      snapshotId: string
      name: string
    }) {
      return await self.apiClient.updateSnapshot({
        scriptId: self.id,
        snapshotId,
        name,
      })
    },
    async disableSlack() {
      await self.apiClient.removeScriptFromSlack({ scriptId: self.id })
      self.setSlackWebhook(false)
    },
    async exportBracketsList() {
      const scriptId = self.id
      await self.rootStore.doDebug()
      const { fileName, text } =
        await self.apiClient.exportBracketsList(scriptId)
      saveTextToFile({ text, fileName })
      self.trackEvent(SCRIPT_EXPORTED_RUNDOWN, { scriptId })
    },
    async exportCharacterReport() {
      const scriptId = self.id
      await self.rootStore.doDebug()
      const date = new Date()
      const timestamp = format(date, PDF_HEADER_DATEMASK)
      const { fileName, text } = await self.apiClient.exportCharacterReport({
        scriptId,
        timestamp,
      })
      saveTextToFile({ text, fileName })
      self.trackEvent(SCRIPT_EXPORTED_CHARACTER_REPORT, { scriptId })
    },
    async exportFdx() {
      const scriptId = self.id
      await self.rootStore.doDebug()
      const { fileName, text, contentType } = await self.apiClient.exportFdx({
        scriptId,
      })
      saveTextToFile({ text, fileName, contentType })
      self.trackEvent(BETA_EXPORTED_FDX, { scriptId })
    },
    async exportFountain() {
      const scriptId = self.id
      await self.rootStore.doDebug()
      const { fileName, text, contentType } =
        await self.apiClient.exportFountain(scriptId)
      saveTextToFile({ text, fileName, contentType })
      self.trackEvent(SCRIPT_EXPORTED_FOUNTAIN, { scriptId })
    },
    async exportPrompter() {
      const scriptId = self.id
      await self.rootStore.doDebug()
      const { fileName, text } = await self.apiClient.exportPrompter(scriptId)
      saveTextToFile({ text, fileName })
      self.trackEvent(SCRIPT_EXPORTED_TXT, { scriptId })
    },
    async exportLineData() {
      await self.rootStore.doDebug()
      const response = await self.scrapi.scripts.getLines({
        params: { id: self.id },
      })
      if (response.status === 200) {
        const { lines } = response.body
        saveTextToFile({
          text: JSON.stringify(lines, null, 2),
          fileName: `${self.name}.lines.json`,
        })
        return true
      }
      return false
    },

    async unresolveCommentThread(threadId: string) {
      const thread = self.commentData.getThread(threadId)
      if (!(thread && thread.resolvedAt)) {
        return
      }

      // optimistic update, save timestamp for rollback
      const originalTimestamp = thread.resolvedAt.toISOString()
      thread.rootComment.setUnresolved(new Date().toISOString())

      try {
        await self.rootStore.doDebug()
        await self.apiClient.unresolveComment({
          scriptId: self.id,
          commentId: threadId,
        })

        const editorView = self.observableEditor?.editorView
        if (editorView) {
          pmHelpers.updateCommentMark({
            commentId: threadId,
            editorView,
            resolved: false,
          })
          self.commentData.selectThread(threadId)
        }
      } catch (e) {
        // rollback
        self.commentData
          .getThread(threadId)
          ?.rootComment.setResolved(originalTimestamp)
      }
    },
    async resolveCommentThread(threadId: string) {
      // optimistic update
      const thread = self.commentData.commentMap.get(threadId)
      if (!thread) {
        return
      }
      const originalTimestamp = thread.updatedAt.toISOString()
      thread.setResolved(new Date().toISOString())

      try {
        await self.rootStore.doDebug()
        const result = await self.apiClient.resolveComment({
          scriptId: self.id,
          commentId: threadId,
        })
        const editorView = self.observableEditor?.editorView
        if (result.status === 'success' && editorView) {
          pmHelpers.updateCommentMark({
            commentId: threadId,
            editorView,
            resolved: true,
          })
        }
      } catch {
        // rollback optimistic update
        self.commentData.commentMap
          .get(threadId)
          ?.setUnresolved(originalTimestamp)
      }
    },
    async deleteComment(comment: IComment) {
      if (!comment) {
        return
      }
      // optimistic update
      comment.setDeleted(new Date().toISOString())

      try {
        await self.rootStore.doDebug()
        // if the comment is the last comment in a thread, we need to remove
        // the comment mark from the script as well
        const threadId = comment.threadId
        const isFirstCommentInThread = comment.id === threadId

        const { parentDeleted, replyCount } =
          await self.apiClient.deleteComment({
            scriptId: self.id,
            commentId: comment.id,
          })

        const firstCommentIsDeleted = isFirstCommentInThread || parentDeleted
        const shouldRemoveMark = replyCount === 0 && firstCommentIsDeleted

        if (shouldRemoveMark && self.observableEditor) {
          pmHelpers.removeCommentMark({
            id: threadId,
            editorView: self.observableEditor.editorView,
          })
        }
      } catch {
        comment.setUndeleted()
      }
    },
    async editComment({
      commentId,
      text,
    }: {
      commentId: string
      text: string
    }) {
      const comment = self.commentData.commentMap.get(commentId)
      if (!comment) {
        return
      }

      // optimistic update, roll back in catch
      const { text: originalText, updatedAt: originalTimestamp } = comment
      comment.update({
        text,
        timestamp: new Date().toISOString(),
      })

      try {
        await self.rootStore.doDebug()
        await self.apiClient.editComment({
          scriptId: self.id,
          commentId,
          text,
        })
      } catch {
        // rollback
        comment.update({
          text: originalText ?? '',
          timestamp: originalTimestamp.toISOString(),
        })
      }
    },
    // no optimistic behavior here, promise is handled in the UI
    async addCommentToThread({
      threadId,
      text,
    }: {
      threadId: string
      text: string
    }) {
      await self.rootStore.doDebug(5)
      const result = await self.apiClient.addCommentToThread({
        scriptId: self.id,
        parentId: threadId,
        text,
      })

      const { id, name, avatar } = self.rootStore.user
      self.commentData.ingestComment({
        ...result,
        creator: {
          id,
          name,
          avatar,
        },
      })
    },
  }))
