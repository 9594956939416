import { observer } from 'mobx-react-lite'

import { ChooseSnapshotCard, SnapshotCard } from './SnapshotCard'
import { useSnapshotData } from './useSnapshotLandData'
import { useSnapshotQueryParams } from './useSnapshotQueryParams'

export const LoadableSnapshotCard = ({
  scriptId,
  snapshotId,
  onClose,
}: {
  scriptId: string
  snapshotId: string
  compare: boolean
  onClose?: Callback
}) => {
  const { data } = useSnapshotData({ scriptId, snapshotId })
  return <SnapshotCard data={data} selected sticky onClose={onClose} />
}

export const StickySnapshotCard = observer(function StickySnapshotCard({
  position,
}: {
  position: 'top' | 'bottom'
}) {
  const { snap1, snap2, scriptId, view, dismissTop, dismissBottom } =
    useSnapshotQueryParams()
  const snapshotId = position === 'bottom' ? snap2 : snap1

  if (!(snapshotId && scriptId)) {
    return <ChooseSnapshotCard compare={view !== 'static'} />
  }

  const handleClose = () => {
    if (position === 'top') {
      dismissTop()
    } else {
      dismissBottom()
    }
  }

  return (
    <LoadableSnapshotCard
      scriptId={scriptId}
      snapshotId={snapshotId}
      compare={view !== 'static'}
      onClose={view === 'static' ? undefined : handleClose}
    />
  )
})
