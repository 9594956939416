import { observer } from 'mobx-react-lite'

import { Toolbar } from '@components/Toolbar'
import { TabItem } from '@components/Toolbar/ToolbarTabs'
import { useMst, useNavigation } from '@hooks'

import * as helpers from './helpers'

type SnapshotTabItem = TabItem & {
  id: helpers.SnapshotViewType
}

export const SnapshotLandToolbar = observer(function SnapshotLandToolbar() {
  const { location } = useMst()
  const { changeSearch } = useNavigation()
  const params = helpers.getSnapshotQueryParams(location.search)

  const setSelectedTab = (newType: string | null) => {
    if (newType && newType !== params.view && helpers.isViewType(newType)) {
      const newSearch = helpers.swapViewType({
        newType,
        search: location.search,
      })
      changeSearch(newSearch)
    }
  }

  const items: SnapshotTabItem[] = [
    { id: 'static', icon: 'fa-camera', label: 'Snapshot' },
    { id: 'diff', icon: 'fa-columns', label: 'Side-by-side' },
    { id: 'asterisk', icon: 'fa-asterisk', label: 'Asterisk' },
  ]

  return (
    <Toolbar>
      <Toolbar.Section position="left">
        <Toolbar.Tabs
          selectedTab={params.view}
          onSelectTab={setSelectedTab}
          items={items}
        />
      </Toolbar.Section>
    </Toolbar>
  )
})
