import { Text } from '@mantine/core'
import cn from 'classnames'

import styles from './BodyPlaceholder.module.scss'

const getMessage = ({
  issueType,
  compare,
}: {
  issueType?: 'error' | 'loading'
  compare?: boolean
}) => {
  if (issueType === 'error') {
    return 'Error loading snapshot'
  }
  if (issueType === 'loading') {
    return 'Loading'
  }

  return compare ? 'Choose a snapshot to compare' : 'Choose a snapshot'
}

export const BodyPlaceholder = ({
  compare,
  issueType,
}: {
  issueType?: 'error' | 'loading'
  compare?: boolean
}) => (
  <div className={cn(styles.bodyPlaceholder)}>
    <Text c="gray.6">{getMessage({ issueType, compare })}</Text>
  </div>
)
