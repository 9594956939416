import React from 'react'

import { ActionIcon, Badge, Menu, Tooltip } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { useRoute } from 'wouter'

import { FancyMenuItem } from '@components/FancyMenuItem'
import {
  showPushScriptToPrompterModal,
  showUpgradeInquiryModal,
} from '@components/Modals'
import { showPrintScriptModal } from '@components/Modals/PrintScriptModal/PrintScriptModal'
import { showPrompterViewInfo } from '@components/PrompterView'
import { Spinner } from '@components/Spinner'
import { ExportOptions, Keys, useFileCreation, useShortcuts } from '@hooks'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util/pathConfigs'

import { NumberingItems } from './NumberingItems'
import { PaidMenuItem } from './PaidMenuItem'

import styles from './ExtrasMenu.module.scss'

export const ExtrasMenu = observer(function ExtrasMenu() {
  const { currentOrg, user, currentScript, view } = useMst()
  const creation = useFileCreation()
  const maxHeight = view.dimensions.scriptScroller.height - 20
  const [isOpen, setIsOpen] = React.useState(false)
  const [isSplitEditor] = useRoute(ROUTE_PATTERNS.splitEditor)

  const handleOpenChange = (value: boolean) => {
    if (isOpen && !value) {
      currentScript?.observableEditor?.focusEditor()
    }
    setIsOpen(value)
  }

  const showPrompterSubtitle =
    currentOrg?.hasPrompterAvailable &&
    currentOrg?.hasPrompterDisabled &&
    !currentScript?.isPrivate

  const p2pDisabled = currentScript?.isPrivate || !user.canPushToPrompter

  const handleCreatePdf = (fileType: 'print' | 'download') => {
    creation.createFile({ fileType, script: currentScript })
  }

  const handleExport = (fileType: ExportOptions) => {
    creation.createFile({ fileType, script: currentScript })
  }

  const handlePrompterPush = () => {
    if (currentScript) {
      showPushScriptToPrompterModal(currentScript)
    }
  }

  const { getItemProps } = useShortcuts(
    {
      print: {
        keys: [Keys.CMD, 'P'],
        action: () => {
          handleCreatePdf('print')
        },
      },
      export: {
        keys: [Keys.CMD, Keys.SHIFT, 'E'],
        action: () => handleCreatePdf('download'),
      },
      p2p: {
        keys: [Keys.CMD, Keys.SHIFT, 'P'],
        action: handlePrompterPush,
        disabled: p2pDisabled,
      },
    },
    {
      skip: isSplitEditor,
    },
  )

  if (!currentOrg || !currentScript) return null

  const handlePrompterView = () => {
    currentScript.setShowPrompterView(true)
    if (user.prefs.prompterView === undefined) {
      showPrompterViewInfo()
    }
  }

  const { isScreenplay } = currentScript

  const characterReportTitle = `Export ${
    isScreenplay ? 'character' : 'cast'
  } report`

  return (
    <>
      {creation.isCreating && <Spinner delayMs={300} fullScreen />}
      <Menu position="bottom-end" opened={isOpen} onChange={handleOpenChange}>
        <Menu.Target>
          <Tooltip
            label="Export, element numbering, and more"
            disabled={isOpen}
          >
            <ActionIcon className={styles.extras_button}>
              <i className="fa fa-ellipsis-h"></i>
            </ActionIcon>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown className={styles.extras_dropdown} style={{ maxHeight }}>
          {/* only render innards when open to avoid unncessary re-renders */}
          {isOpen && (
            <>
              <FancyMenuItem title="Print..." {...getItemProps('print')} />
              <FancyMenuItem
                title="Export PDF"
                {...getItemProps('export')}
              ></FancyMenuItem>
              <FancyMenuItem
                title="Print preferences..."
                onClick={() =>
                  showPrintScriptModal({
                    docType: 'script',
                    script: currentScript,
                  })
                }
              ></FancyMenuItem>
              <Menu.Divider />
              <FancyMenuItem
                title="Export to Final Draft"
                onClick={() => handleExport('fdx')}
              ></FancyMenuItem>
              <FancyMenuItem
                title="Export Fountain"
                onClick={() => handleExport('fountain')}
              ></FancyMenuItem>
              <FancyMenuItem
                title="Export line data"
                onClick={() => handleExport('line-data')}
              ></FancyMenuItem>
              <Menu.Divider />
              <PaidMenuItem
                title={characterReportTitle}
                onClick={() => handleExport('characterReport')}
                isUnpaidOrg={currentOrg.isUnpaid}
              ></PaidMenuItem>
              {!isScreenplay && (
                <PaidMenuItem
                  title="Export brackets list"
                  onClick={() => handleExport('bracketsList')}
                  isUnpaidOrg={currentOrg.isUnpaid}
                ></PaidMenuItem>
              )}
              <Menu.Divider />
              <NumberingItems
                docNumbering={
                  currentScript.pmEditor.docInfo?.elementNumbers ?? {}
                }
                isUnpaidOrg={currentOrg.isUnpaid}
                script={currentScript}
              />
              {!isScreenplay && (
                <>
                  <Menu.Divider />
                  <FancyMenuItem
                    title="Prompter view"
                    onClick={handlePrompterView}
                    badge={<Badge>BETA</Badge>}
                  ></FancyMenuItem>
                  <PaidMenuItem
                    title="Export prompter text"
                    onClick={() => handleExport('prompter')}
                    isUnpaidOrg={currentOrg.isUnpaid}
                  ></PaidMenuItem>
                  <FancyMenuItem
                    title="Push to prompter..."
                    subTitle={
                      showPrompterSubtitle ? 'Contact us to enable' : undefined
                    }
                    {...getItemProps('p2p')}
                  ></FancyMenuItem>
                </>
              )}
              {currentOrg.isUnpaid && (
                <>
                  <Menu.Divider />
                  <Menu.Item
                    onClick={() => showUpgradeInquiryModal(currentOrg)}
                  >
                    Request an upgrade ✨
                  </Menu.Item>
                </>
              )}
            </>
          )}
        </Menu.Dropdown>
      </Menu>
    </>
  )
})
